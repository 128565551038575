import gql from 'graphql-tag';

export const GET_TASKS_BY_AGENT_ID = gql`
  query GetTasksByAgentId($where: [WhereFilter]) {
    fulfillmentTasks(where: $where) {
      id
      classification
      status
      activeOrderId
      orderIds
      orders {
        id
        address {
          contactName
        }
        status
        shipMethod {
          id
        }
        fulfillmentTask {
          id
        }
        project {
          id
        }
        history {
          event
        }
      }
      assignedBy {
        id
        person {
          firstName
          lastName
        }
      }
      assignee {
        id
        person {
          firstName
          lastName
        }
      }
      startedOn
      scheduledStart
      expectedShipDate
      createdAt
      updatedAt
    }
  }
`;
