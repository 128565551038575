<template>
  <div>
    <div v-show="isGlobalSpinnerVisible">
      <div class="absolute inset-0 bg-gray-300 bg-opacity-50 z-40">
        <div class="flex h-full items-center justify-center">
          <svg class="animate-spin -ml-1 mr-3 h-14 w-14 text-primary-600" xmlns="http://www.w3.org/2000/svg" fill="none"
            viewBox="0 0 24 24">
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4" />
            <path class="opacity-75" fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
          </svg>
        </div>
      </div>
    </div>
    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, provide, inject, readonly } from 'vue';

// Unique symbol used for provide/inject
export const GLOBAL_SPINNER = Symbol("GLOBAL_SPINNER");

interface SpinnerController {
  isGlobalSpinnerVisible: boolean;
  showSpinner(): void;
  hideSpinner(): void;
  toggleSpinner(): void;
}

export const useLoaderOverlay = (): SpinnerController => {
  const value = inject(GLOBAL_SPINNER);
  if (!value) throw new Error('useLoaderOverlay must be used inside a <LoaderOverlayProvider /> component')
  return value as SpinnerController;
}

export default defineComponent({
  setup() {
    const isGlobalSpinnerVisible = ref(false);
    // Methods to update the state
    const showSpinner = () => (isGlobalSpinnerVisible.value = true);
    const hideSpinner = () => (isGlobalSpinnerVisible.value = false);
    const toggleSpinner = () => (isGlobalSpinnerVisible.value = !isGlobalSpinnerVisible.value);

    // Provide the state and methods to change it
    provide(GLOBAL_SPINNER, {
      isGlobalSpinnerVisible: readonly(isGlobalSpinnerVisible),
      showSpinner,
      hideSpinner,
      toggleSpinner,
    });
    // Return the state so the overlay with spinner can be shown
    // when set to true
    return {
      isGlobalSpinnerVisible,
    };
  },
});
</script>
