<template>
  <button v-if="size === 'sm'" :type="buttonType" :disabled="disabled"
    class="text-center items-center px-2.5 py-1.5 text-xs font-medium rounded shadow-sm" :title="title"
    :class="[buttonClass, $attrs.class]" @click="emit('click')">
    <slot />
  </button>

  <button v-if="size === 'md'" :type="buttonType" :disabled="disabled"
    class="text-center items-center px-3 py-2 text-sm leading-4 font-medium rounded-md shadow-sm" :title="title"
    :class="[buttonClass, $attrs.class]" @click="emit('click')">
    <slot />
  </button>

  <button v-if="size === 'lg'" :type="buttonType" :disabled="disabled"
    class="text-center items-center px-4 py-2 text-sm font-medium rounded-md shadow-sm" :title="title"
    :class="[buttonClass, $attrs.class]" @click="emit('click')">
    <slot />
  </button>

  <button v-if="size === 'xl'" :type="buttonType" :disabled="disabled"
    class="text-center items-center px-4 py-2 text-base font-medium rounded-md shadow-sm" :title="title"
    :class="[buttonClass, $attrs.class]" @click="emit('click')">
    <slot />
  </button>

  <button v-if="size === '2xl'" :type="buttonType" :disabled="disabled"
    class="text-center items-center px-6 py-3 text-base font-medium rounded-md shadow-sm" :title="title"
    :class="[buttonClass, $attrs.class]" @click="emit('click')">
    <slot />
  </button>
</template>

<script lang="ts">
export enum ButtonStyleType {
  DEFAULT = 'default',
  PRIMARY = 'primary',
  INFO = 'info',
  SUCCESS = 'success',
  WARNING = 'warning',
  DANGER = 'danger',
}
</script>

<script setup lang="ts">
import type { PropType } from 'vue';
import { computed } from 'vue';

const emit = defineEmits(['click']);

const props = defineProps({
  size: {
    type: String as PropType<'sm' | 'md' | 'lg' | 'xl' | '2xl'>,
    default: 'sm',
  },
  type: {
    type: String as PropType<ButtonStyleType>,
    default: ButtonStyleType.DEFAULT,
  },
  buttonType: {
    type: String as PropType<"button" | "submit" | "reset" | undefined>,
    default: 'button',
  },
  title: {
    type: String as PropType<string>,
    required: false
  },
  disabled: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
});

const buttonClass = computed(() => {
  let classes;
  switch (props.type) {
    case ButtonStyleType.PRIMARY:
      classes = 'bg-primary-600 text-white border border-transparent hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-600';
      break;
    case ButtonStyleType.INFO:
      classes = 'bg-cyan-600 border border-transparent hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500';
      break;
    case ButtonStyleType.SUCCESS:
      classes = 'bg-green-600 border border-transparent hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500';
      break;
    case ButtonStyleType.WARNING:
      classes = 'bg-orange-600 text-white border border-transparent hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500';
      break;
    case ButtonStyleType.DANGER:
      classes = 'bg-red-600 text-white border border-transparent hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500';
      break;
    default:
      classes = 'bg-white text-gray-700 border border-gray-300 hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-600';
      break;
  }

  if (props.disabled) {
    classes = 'bg-white text-gray-400 border-gray-400 cursor-not-allowed';
  }

  return classes;
});

</script>
