import { createApp, type App as VueApp } from 'vue';
import localforage from 'localforage';
import * as Sentry from '@sentry/electron/renderer';

import '../assets/tailwind.css';
import router from './router';
import App from './App.vue';

// import { loadPlugins } from './utils/loadPlugins'
import { registerBaseComponents } from './utils/registerBaseComponents';

// eslint-disable-next-line
import.meta.env.PROD && (console.info = console.debug = () => { });


// Prepare Sentry as soon as possible on initialization
if (!import.meta.env.DEV) {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.VITE_SENTRY_ENV,
    tracesSampleRate: import.meta.env.PROD ? 0.6 : 1.0,
  });
}

const prepareElectronGlobals = async (app: VueApp) => {
  console.log('Preparing Electron');

  Object.defineProperty(window, 'platform', {
    value: 'electron',
    writable: false,
  });

  window.electron.on('clear-cache', () => {
    // window.localCache.clear();
    alert('Cache Cleared');
  });
  window.electron.on('enable-cache', () => {
    window.electronStoreConfigs.set('cache.enabled', true);
  });
  window.electron.on('disable-cache', () => {
    window.electronStoreConfigs.set('cache.enabled', false);
    window.electron.shell.beep();
  });

  console.warn(window);
  // Register Global APP Components
  await registerBaseComponents(app);
};

const prepareWebGlobals = async (_app: VueApp) => {
  console.debug('Preparing Web Globals');

  const serverUri = import.meta.env.VITE_API_URL;

  Object.defineProperty(window, 'platform', {
    value: 'web',
    writable: false,
  });
  Object.defineProperty(window, 'printer', {
    value: { getDefault: () => ({}) },
    writable: false,
  });
  Object.defineProperty(window, 'apiServerURL', {
    value: serverUri,
    writable: false,
  });

  // @ts-expect-error because its assigned on window
  console.debug(`Using electron global? ${!!window.electron} apiURL ${window.apiServerURL}`);
};

const init = async () => {
  console.debug('Running prod? ' + import.meta.env.PROD);

  Object.defineProperty(window, 'localConfigs', {
    value: localforage.createInstance({ name: 'configs' }),
    writable: false,
  });

  Object.defineProperty(window, 'cacheKey', {
    value: import.meta.env.VITE_API_URL.replace(/[^a-zA-Z ]/g, ''),
    writable: false,
  });

  Object.defineProperty(window, 'localCache', {
    value: localforage.createInstance({ name: 'app-local-cache' }),
    writable: false,
  });

  const app = createApp(App);

  if (window?.electron) await prepareElectronGlobals(app);
  else await prepareWebGlobals(app);

  // Mount Router
  app.use(router).mount('#app');
};

console.groupCollapsed('Init App');
init();
console.groupEnd();
