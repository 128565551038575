import gql from 'graphql-tag';

export const GET_TICKETS = gql`
  query GetTickets($projectId: ID!) {
    tickets(projectId: $projectId) {
      id
      name
      referenceId
      ignored
      mappedItems {
        id
        quantity
        product {
          id
          name
        }
      }
      isMapped
    }
  }
`;
