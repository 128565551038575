import gql from 'graphql-tag';

export const COMPANY_INFO = gql`
  fragment CompanyInfo on Company {
    id
    name
    address {
      id
      street1
      street2
      city
      state
      postalCode
      type
      companyName
      contactName
      country
    }
  }
`;
