import gql from 'graphql-tag';

export const GET_SHIP_METHODS = gql`
  query GetProjectShipMethods {
    shipMethods {
      id
      name
      carrier {
        id
        name
      }
      service
    }
  }
`;
