import gql from 'graphql-tag';

export const ORDER_STATUSES = gql`
  fragment OrderStatuses on ProjectStats {
    created
    verified
    verifying
    failed
    cancelling
    cancelled
    queued
    preparing
    prepared
    scheduling
    scheduled
    fulfilling
    completing
    completed
    shipping
    shipped
  }
`;
