import gql from 'graphql-tag';
import { ORDER_STATUSES } from '../fragments/ORDER_STATUSES';

export const GET_PROJECT_STATS_BY_ID = gql`
  query GetProjectStatsByID($filter: ProjectStatsFilter) {
    projectStats(filter: $filter) {
      id
      project {
        name
        startDate
        endDate
        eventDate
      }
      statuses {
        ...OrderStatuses
      }
      totalOrders
    }
  }

  ${ORDER_STATUSES}
`;
