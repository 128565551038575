import { computed, ref, type RendererElement, type RendererNode, type VNode } from 'vue';
import { v4 } from 'uuid';

export enum AlertType {
  INFO,
  URGENT,
  WARNING,
  CRITICAL,
  SUCCESS,
}

export type AlertMessage = {
  title: string;
  body: string | VNode<RendererNode, RendererElement, { [key: string]: any }>;
};

/**
 * @typedef {Object} Alert - 'Alert'
 * @property {string} id - a string property of SpecialType
 * @property {AlertType} type - a number property of SpecialType
 * @property {AlertMessage} message - an optional number property of SpecialType
 * @property {string=} channel - an optional channel to group messages
 * @property {number=} timeout - an optional timeout to dismiss in milliseconds
 */
export type Alert = {
  id?: string;
  type: AlertType;
  message: AlertMessage;
  channel?: string;
  timeout?: number;
};

/**
 * Creates an alert object with the provided data, including a generated ID.
 *
 * @param {Partial<Alert>} data - the partial alert data to be included in the new alert object
 * @return {Alert} the newly created alert object
 */
const alertFactory = (data: Partial<Alert>): Alert => {
  return {
    id: v4(),
    ...data,
  } as Alert;
};

export const alertsQueue = ref([] as Alert[]);

export const alerts = computed(() => alertsQueue.value);

/**
 * Sends an alert and adds it to the alerts queue.
 * If the alert type is SUCCESS and the timeout is not defined, sets the timeout to 1250.
 *
 * @param {Alert} alert - the alert to be sent
 * @return {void}
 */
export const sendAlert = (alert: Alert): void => {
  if (alert.type === AlertType.SUCCESS && alert.timeout == undefined) {
    alert.timeout = 1250;
  }
  alertsQueue.value.push(alertFactory(alert));
};

/**
 * Dismisses an alert by its ID.
 *
 * @param {string} id - the ID of the alert to dismiss
 * @return {void}
 */
export const dismissAlert = (id: string): void => {
  const alertIdx = alertsQueue.value.findIndex((a) => a.id === id);
  if (alertIdx !== -1) alertsQueue.value.splice(alertIdx, 1);
};

/**
 * Dismisses all alerts in the queue.
 *
 * @return {void}
 */
export const dismissAll = (): void => {
  alertsQueue.value = [];
};
