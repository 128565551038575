<template>
  <BarChart v-if="!loading" ref="graphRef" :chart-data="agentsBarChartGraphData(data)" :options="options" />
  <div v-else class="flex h-60 items-center justify-center">
    <svg class="animate-spin -ml-1 mr-3 h-14 w-14 text-primary-600" xmlns="http://www.w3.org/2000/svg" fill="none"
      viewBox="0 0 24 24">
      <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4" />
      <path class="opacity-75" fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
    </svg>
  </div>
</template>

<script setup lang="ts">
import type { PropType } from 'vue';
import type { ChartOptions } from "chart.js";
import type { ExtractComponentData } from 'vue-chart-3';

import { ref } from 'vue';
import { getTimeZoneDate } from "@utils/timeZoneUtils";
import { Chart, registerables } from 'chart.js';
import { BarChart } from 'vue-chart-3';
// @ts-ignore No types available
import autocolors from 'chartjs-plugin-autocolors';

Chart.register(...registerables);
Chart.register(autocolors);

const props = defineProps({
  data: {
    type: Object as PropType<any[]>,
    required: true,
    default: () => []
  },
  loading: {
    type: Boolean as PropType<boolean>,
    required: true,
    default: true
  }
});

const graphRef = ref<ExtractComponentData<typeof BarChart>>();
const options = ref<ChartOptions<'bar'>>({
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
      labels: {
        sort(a, b) {
          return a.text.localeCompare(b.text);
        },
      }
    },
    title: {
      display: true,
      text: 'Agent Orders Completed',
      padding: {
        bottom: 20
      }
    },
  },
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true
    }
  },
  animation: false
});

const agentsBarChartGraphData = (data: any[]) => {
  const labels: Set<Date> = data.reduce(
    (labels: Set<Date>, record: any) => labels.add(new Date(record.date)),
    new Set<Date>() // unique values
  );

  let dates: string[] = Array.from<Date>(labels)
    .sort((date1, date2) => date1.valueOf() - date2.valueOf())
    .map((date: Date) => { return getTimeZoneDate(date, { weekday: 'long' }) });

  let howManyDaysToShow = 7;
  dates = [...new Set(dates)].slice(-howManyDaysToShow);

  type DataResult = { label: string, data: number[] }
  type DataSet = { [id: string]: DataResult };

  const agentDataReducer = (stats: DataSet, record: any): DataSet => {

    if (!stats[record.agent.id]) {
      const initalData = [0]
      dates.forEach((d) => {
        initalData.push(0)
      });
      stats[record.agent.id] = {
        label: record.agent.person.firstName !== record.agent.person.lastName ? `${record.agent.person.firstName} ${record.agent.person.lastName}` : `${record.agent.person.lastName}`,
        data: initalData,
      }
    }

    const date = getTimeZoneDate(record.date, { weekday: 'long' });
    stats[record.agent.id].data[dates.indexOf(date)] = record.count

    return stats;
  };

  const resultReduced: DataSet = data.reduce(agentDataReducer, {});
  const dataSets = Object.values(resultReduced);

  return {
    labels: dates,
    datasets: dataSets,
  }
};

</script>
