import gql from 'graphql-tag';

export const GET_PROJECT_EMAILS = gql`
  query GetProjectEmailsById($id: ID!) {
    project(id: $id) {
      id
      name
      emails {
        invalidOrderAddress {
          id
          name
          template
          delay
        }
        shipOrderConfirmation {
          id
          name
          template
          delay
        }
        finalHoldAtWillcall {
          id
          name
          template
          delay
        }
      }
    }
  }
`;
