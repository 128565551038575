import type { App } from 'vue';
import type { HttpOptions, NormalizedCacheObject } from '@apollo/client/core';

import { ApolloLink, ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client/core';
import { onError } from '@apollo/client/link/error';
import { DefaultApolloClient, provideApolloClient } from '@vue/apollo-composable';
import { createUploadLink } from 'apollo-upload-client';
import auth from '../auth/auth';

export interface GraphQLClient {
  client: ApolloClient<NormalizedCacheObject>;
  install: (app: App) => void;
  provider: () => void;
}

export const createApolloClient = async (uri: string): Promise<GraphQLClient> => {
  // Cache implementation
  const cache = new InMemoryCache({ addTypename: true });

  const httpOptions: HttpOptions = { uri };

  // HTTP connection to the API
  const httpLink = createHttpLink(httpOptions);
  // HTTP octet/stream data to the API
  const uploadLink = createUploadLink({ ...httpOptions, headers: { 'Apollo-Require-Preflight': 'true' } });
  // Log any GraphQL errors or network error that occurred
  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.map(({ message, locations, path }) =>
        console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
      );
    if (networkError) console.error(`[Network error]: ${networkError}`);
  });

  const terminatingLink = ApolloLink.split((operation) => operation.getContext().hasUpload, uploadLink, httpLink);

  // Strip __typename from serialized data
  const omitTypename = (key: string, value: unknown) => {
    return key === '__typename' ? undefined : value;
  };

  // Auth Token Middleware
  const authLink = new ApolloLink((operation, forward) => {
    console.groupCollapsed('Apollo Auth Link');
    console.debug('Apollo Auth Link', operation);

    if (operation.operationName !== 'login') {
      const token = auth.getAccessToken(httpOptions.uri as string);

      operation.setContext({
        headers: {
          ...(token ? { Authorization: `Bearer ${token}` } : {}),
        },
      });
    }

    console.debug('Apollo Link', operation.getContext());

    if (operation.variables && !operation.getContext().hasUpload) {
      operation.variables = JSON.parse(JSON.stringify(operation.variables), omitTypename);
    }

    console.groupEnd();
    return forward(operation);
  });

  // Create the apollo client
  const apolloClient = new ApolloClient<NormalizedCacheObject>({
    cache,
    link: authLink.concat(errorLink.concat(terminatingLink)),
    defaultOptions: {
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      },
    },
  });

  const install = (app: App) => {
    app.provide(DefaultApolloClient, apolloClient);
  };

  const provider = () => {
    console.debug('Loading Apollo Client');
    provideApolloClient(apolloClient);
  };

  return {
    client: apolloClient,
    provider,
    install,
  };
};
