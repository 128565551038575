import type { Mutator, GetState } from '@composables/useStore';
import { createStore } from '@composables/useStore';
import { createUser } from '../models/User';

import { people } from '../tests/fixtures';

type UserState = {
  loading: boolean;
  users: Array<User>;
};

type UserAccessors = {
  getUserById: (id: string) => Promise<User | undefined>;
  getUsers: () => Promise<boolean>;
  addUser: (user: User) => Promise<boolean>;
  updateUser: (user: User) => Promise<boolean>;
  clearAll: () => void;
  removeUser: (user: User) => Promise<boolean>;
};

//FIXME API Call
const api = {
  async generateNewUser(newUser: User) {
    console.debug('Creating User', { ...newUser });
    return createUser({ ...newUser });
  },
};

const accessorsCreator = (mutate: Mutator<UserState>, _get: GetState<UserState>): UserAccessors => ({
  async getUserById(id: string): Promise<User | undefined> {
    let user;
    mutate((state) => {
      user = state.users.find((u) => u.id === id);
    });
    return user || undefined;
  },
  async getUsers(): Promise<boolean> {
    //FIXME API CALL FOR USERS
    mutate((state) => {
      state.loading = true;
      (state.users = process.env.NODE_ENV !== 'production' ? people : []), (state.loading = false);
    });
    return true;
  },
  async addUser(user: User): Promise<boolean> {
    const newUser = await api.generateNewUser(user);
    if (!newUser) return false;

    console.debug(user);
    mutate((state) => state.users.push(newUser));
    return true;
  },
  async updateUser(user: User): Promise<boolean> {
    mutate((state) => {
      const existing = state.users.find((u) => (u.id = user.id));
      if (existing) {
        console.debug('Updating', user);
        existing.group = [user.group];
        existing.username = user.username;
        existing.person = user.person;
      }
    });
    return true;
  },
  async removeUser(user: User): Promise<boolean> {
    mutate((state) => {
      const users: User[] = state.users.filter((u) => {
        console.debug(`User ${u.id} is ${user.id}`);
        return u.id !== user.id;
      });
      if (users.length < state.users.length) state.users = users;
    });

    return true;
  },
  clearAll() {
    mutate((state) => (state.users = []));
  },
});

const initialState = {
  loading: false,
  users: people,
};

const usersStore = createStore<UserState, UserAccessors>({
  name: 'UserStore',
  initialState,
  accessorsCreator,
  mutatorHook: (state) => console.debug('New User State', state),
});

export default usersStore;
