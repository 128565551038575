<template>
  <!-- Top nav-->
  <header id="app-header" class="flex w-full bg-white z-40" :class="isMacOS ? 'items-end' : 'h-16 items-center'">
    <slot name="header" />
  </header>

  <!-- Bottom section -->
  <div class="flex" style="height: calc(100vh - 4rem)">
    <aside class="flex min-h-0">
      <slot name="sidenav" />
    </aside>
    <!-- Main area -->
    <main class="flex h-full flex-grow overflow-auto">
      <!-- Content -->
      <slot />
    </main>
  </div>

  <Toasts class="mt-16" />
</template>

<script setup lang="ts">
import { computed } from 'vue';
import Toasts from '@components/common/Toasts.vue';

const isMacOS = computed(() => window?.electron?.platform == 'darwin');
</script>

<style lang="postcss">
* {
  @apply focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-offset-transparent focus:ring-primary-500;
}

body {
  height: auto;
  width: 100%;
  overflow: hidden;
  @apply bg-gray-100;
}

#app-header {
  -webkit-user-select: none;
  -webkit-app-region: drag;
}
</style>