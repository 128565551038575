import gql from 'graphql-tag';

export const GET_AGENTS = gql`
  query GetAgents {
    users {
      id
      person {
        email
        firstName
        lastName
      }
      group {
        name
      }
      createdAt
      updatedAt
      deletedOn
    }
  }
`;
