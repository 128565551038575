<template>
  <router-view />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import usersStore from './services/usersStore';


export default defineComponent({
  setup() {
    usersStore.provider();
  },
});
</script>
