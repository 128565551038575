import gql from 'graphql-tag';

export const GET_ORDERS = gql`
  query GetOrders(
    $project: ID!
    $sort: [SortOrder]
    $limit: Int
    $before: String
    $after: String
    $where: [WhereFilter]
  ) {
    orders(project: $project, limit: $limit, sort: $sort, after: $after, before: $before, where: $where) {
      meta {
        totalRecords
      }
      nodes {
        id
        status
        createdAt
        clientOrderId
        project {
          id
          name
        }
        shipSetting {
          id
          name
          color
          shipMethod {
            id
            name
            carrier {
              id
              name
              displayName
            }
          }
        }
        shipMethod {
          id
          name
          service
          carrier {
            id
            name
            displayName
          }
        }
        address {
          contactName
          email
          street1
          street2
          street3
          city
          state
          postalCode
          country
          type
          verified
        }
        tickets {
          ticket {
            id
            name
          }
          quantity
        }
        classification
        fulfillmentTask {
          id
        }
        shipment {
          id
          shippedOn
          service
          parcel {
            height
            width
            length
            weight
          }
          trackingCode
          carrier {
            id
            name
          }
        }
        lineItems {
          id
          product {
            id
            name
            scanType
            weightOz
            shortName
          }
          reference
          scanValue
          status
        }
        history {
          user
          event
          date
          details
        }
      }
    }
  }
`;
