import type { RouteRecordRaw } from 'vue-router';
import { createRouter, createWebHistory, createMemoryHistory } from 'vue-router';

import Wrapper from './views/AppWrapper.vue';
import LoginView from './views/login/Login.credentials.vue';

import { SetupRoutes } from './views/setup/routes';
import { ProjectRoutes } from './views/projects/routes';
import { ArchiveProjectRoutes } from './views/projects/archiveRoutes';
import { ShippingRoutes } from './views/shipping/routes';
import { FulfillmentRoutes } from './views/fulfillment/routes';
import { UserRoutes } from './views/users/routes';
import { SettingsRoutes } from './views/settings/routes';
import { DashboardRoutes } from './views/dashboard/routes';

import { isAuthenticated } from './composables/useAuth';

declare module 'vue-router' {
  interface RouteMeta {
    // is optional
    isAdmin?: boolean;
    // must be declared by every route
    requiresAuth: boolean;
    readOnly?: boolean;
  }
}

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'root',
    redirect: '/login',
    // redirect: '/test',
  },
  {
    path: '/test',
    component: Wrapper,
    children: [
      {
        path: '',
        name: 'test',
        component: () => import('./views/test/TESTPAGE.vue'),
        meta: { requiresAuth: false, readOnly: false },
      },
    ],
  },
  ...SetupRoutes,
  {
    path: '/login/:hasTimedOut?',
    name: 'login',
    meta: {
      requiresAuth: false,
    },
    props: true,
    component: LoginView,
    beforeEnter: async () => {
      console.debug('Entering /login');
      // @ts-expect-error no-explicit any but ignore
      if (!window?.apiServerURL) {
        console.warn('Unable to resolve API URL');
        return { name: 'setup' };
      }
      if (isAuthenticated.value) {
        return { path: '/app' };
      }
      return true;
    },
  },
  {
    path: '/app',
    component: Wrapper,
    meta: {
      requiresAuth: true,
      readOnly: false,
    },
    beforeEnter: () => {
      console.debug('Entering App');
      console.groupCollapsed('App Tree Navigation.');
      if (!isAuthenticated.value) {
        console.warn('Not authenticated. Returning to login');
        return { name: 'login' };
      }
      console.groupEnd();
    },
    children: [
      ...DashboardRoutes, // default route if exact '/app'
      ...ArchiveProjectRoutes,
      ...ProjectRoutes,
      ...ShippingRoutes,
      ...FulfillmentRoutes,
      ...SettingsRoutes,
      ...UserRoutes,
    ],
  },
];

const router = createRouter({
  routes,
  history: window?.electron ? createMemoryHistory() : createWebHistory(),
});

router.beforeEach((to, from) => {
  console.groupCollapsed('Global Navigation');
  console.info(
    `Routing from ${from.name?.toString() ?? 'root'} to ${to.name?.toString()}\n`,
    `|PATHS| '${from.fullPath}' --to-> '${to.fullPath}'\n`,
    `|PARAMS| ${JSON.stringify(from.params)} --to-> ${JSON.stringify(to.params)}`
  );
  console.info(`${to.path?.toString()} requires auth`, to.meta.requiresAuth);

  console.info('Route Marked Read Only', to.meta.readOnly);
  if (from.meta.readOnly && !(to?.name as string)?.includes('projects')) {
    to.meta.readOnly = from.meta.readOnly;
  }
  console.groupEnd();
});

export default router;
