<template>
  <slot />
</template>

<script lang="ts">
import type {
  PropType,
  ComputedRef} from 'vue';
import {
  defineComponent,
  ref,
  provide,
  inject,
} from 'vue';

import type { MultiFormStep } from '@components/common/MultiStepForm.vue';
import { useStepper } from '@composables/useStepper';

// Unique symbol used for provide/inject
const SETUP_STATE_PROVIDER = 'SETUP_STATE_PROVIDER';

type UpdateStatus = (
  id: ComputedRef<number>,
  status: 'complete' | 'incomplete',
) => void

export type InjectableSetupState = {
  steps: Array<MultiFormStep>
  current: ComputedRef<number>
  goToNext: () => boolean
  updateStatus: UpdateStatus
}

// To be used to access state and methods to change state
export const useStateProvider = (): InjectableSetupState => {
  const value = inject(SETUP_STATE_PROVIDER);

  if (!value) {
    throw new Error(
      'useStateProvider must be used inside of the <SetupStateProvider /> component.',
    );
  }

  return value as InjectableSetupState;
};

export default defineComponent({
  props: {
    initialState: {
      type: Array as PropType<MultiFormStep[]>,
      required: true,
    },
  },
  setup(props) {
    const steps = ref(props.initialState);
    const { current, goToNext } = useStepper({
      start: 1,
      max: props.initialState.length,
    });

    const updateStatus: UpdateStatus = (stepId, status) => {
      steps.value = steps.value.map((s) => {
        if (s.id === stepId.value) s.status = status;
        return s;
      });
    };

    provide(SETUP_STATE_PROVIDER, {
      steps,
      current,
      goToNext,
      updateStatus,
    });
  },
});
</script>
