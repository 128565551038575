import gql from 'graphql-tag';
import { COMPANY_INFO } from '../fragments/COMPANY_INFO';

export const GET_PROJECT_FULL = gql`
  query GetProjectById($id: ID!) {
    project(id: $id) {
      id
      name
      startDate
      eventDate
      endDate
      serviceToken
      emails {
        invalidOrderAddress {
          id
          name
        }
        shipOrderConfirmation {
          id
          name
        }
        finalHoldAtWillcall {
          id
          name
        }
      }
      defaultReturnAddress {
        id
        companyName
        contactName
        street1
        street2
        street3
        phone
        city
        state
        postalCode
        country
      }
      gateCompany {
        id
        name
        ...CompanyInfo
      }
      ticketCompany {
        id
        name
        ...CompanyInfo
      }
      members {
        id
      }
    }
  }

  ${COMPANY_INFO}
`;
