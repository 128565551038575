<template>
  <Section>
    <Form title="Create Admin User Login" description="Add administration user">
      <GridGroup>
        <GridField title="First Name" placeholder="Justin" :value="firstName" @change="handleChange" />
        <GridField title="Last Name" placeholder="Smith" :value="lastName" @change="handleChange" />
        <GridField title="Username" placeholder="JSmth" :value="username" @change="handleChange" />
        <GridField title="Password" placeholder="supersecret" :value="password" @change="handleChange" />

        <GridField title="Email" required placeholder="user@example.com" :value="email" @change="handleChange" />
      </GridGroup>
    </Form>
    <div class="flex my-4 justify-end">
      <BaseButton size="md" @click="submit">Next</BaseButton>
    </div>
  </Section>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';
import { AlertType, sendAlert } from '@services/toastService';
import { useStateProvider } from '../provider/SetupStateProvider.vue';
import BaseButton from '@components/base/BaseButton.vue';

export default defineComponent({
  setup() {
    const { current, goToNext, updateStatus } = useStateProvider();
    const { push } = useRouter();
    const user = ref({ username: '', password: '', email: '', firstName: '', lastName: '' });

    const handleChange = (event: Event) => {
      const target = event.target as HTMLInputElement;
      const name = target.name as 'username' | 'password';
      console.debug('Setting admin users', target.name);
      user.value[name] = target.value ?? '';
    };

    const isValid = () => true;

    const registerAdmin = async () => {
      try {
        if (!window.apiServerURL) {
          throw new Error('Unable to reach the server. Please try again.');
        }

        const url = await window.electronStoreConfigs.getItem('server.uri') as string;
        console.debug(url);
        // const response = await fetch(url);
        return;
      } catch (err) {
        sendAlert({
          type: AlertType.WARNING,
          message: {
            title: 'Invalid Configs',
            body: err.message,
          },
        });
      }
    };

    const submit = async () => {
      if (isValid()) {
        registerAdmin()
          .then(() => updateStatus(current, 'complete'))
          .then(() => { goToNext() && push({ name: 'setup:step-three' }); });
      } else {
        sendAlert({
          type: AlertType.WARNING,
          message: {
            title: 'Invalid Configs',
            body: 'This configuration is incorrect. Please try again',
          },
          timeout: 3000,
        });
      }
    };

    const username = computed(() => user.value.username);
    const password = computed(() => user.value.password);
    const firstName = computed(() => user.value.firstName);
    const lastName = computed(() => user.value.lastName);
    const email = computed(() => user.value.email);


    return {
      submit,
      email,
      username,
      password,
      firstName,
      lastName,
      handleChange,
    };
  },
});
</script>
