import type { RouteRecordRaw } from 'vue-router';
import Dashboard from './Dashboard.vue';

import { isAdmin } from '@composables/useAuth';

export const DashboardRoutes: Array<RouteRecordRaw> = [
  {
    path: '', // Default route if path is exact /app
    name: 'dashboard',
    component: Dashboard,
    beforeEnter: (): { name: string } | void => {
      console.debug('User is admin?', isAdmin.value);
      if (!isAdmin.value) {
        return { name: 'fulfillment:agent' };
      }
    },
  },
];
