import gql from 'graphql-tag';

export const GET_EMAIL_TEMPLATES = gql`
  query GetEmailTemplates {
    emailTemplates {
      id
      name
      template
      delay
    }
  }
`;
