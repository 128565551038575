import gql from 'graphql-tag';

export const GET_PRODUCT_SCANDATA = gql`
  query GetProductScanDataById($id: ID!) {
    allScanData(productId: $id) {
      id
      scanValue
      wasScanned
      serialNumber
      reference
    }
  }
`;
