import gql from 'graphql-tag';

export const GET_AGENTS_TASKS_BY_PROJECT = gql`
  query GetTasksByProject($where: [WhereFilter], $projectId: ID, $classification: String) {
    users(where: $where) {
      id
      person {
        firstName
        lastName
        email
      }
      group {
        id
        name
      }
      assignedOrders(projectId: $projectId, classification: $classification) {
        classification
        orders
        tasks
        taskCount
        orderCount
      }
    }
  }
`;
