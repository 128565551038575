import gql from 'graphql-tag';

export const GET_INVENTORY = gql`
  query GetInventory($projectId: ID!) {
    inventoryCounts(projectId: $projectId) {
      stock {
        product {
          id
          name
          shortName
          scanType
          mustMatchExactly
          weightOz
          orderLimit
          externalId
          inventoryTotal
          customsSku
          customsDescription
          customsValue
          customsOriginCountry
          hasScanData
        }
        totalUsed
        pending
        rejected
        shipped
      }
    }
  }
`;
