<template>
  <Suspense>
    <RouterView />
  </Suspense>
</template>

<script setup lang="ts">


</script>
