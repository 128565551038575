<template>
  <div aria-live="assertive" class="flex items-end px-4 py-2 pointer-events-none sm:p-2 sm:items-start">
    <div class="w-full flex flex-col items-center space-y-4 sm:items-end">
      <!-- Notification panel, dynamically insert this into the live region when it needs to be displayed -->
      <div v-if="show" v-bind="$attrs" class="
          w-full
          min-w-min
          shadow-lg
          rounded-lg
          pointer-events-auto
          ring-1 ring-black ring-opacity-5
          overflow-hidden
        " :class="`bg-${alertType.style}-100`">
        <div class="p-4">
          <div class="flex items-start">
            <div class="flex-shrink-0">
              <component :is="alertType.is" class="h-6 w-6" :class="`text-${alertType.style}-400`" aria-hidden="true" />
            </div>
            <div class="ml-3 w-0 flex-1 pt-0.5">
              <p class="text-sm font-medium text-gray-900"> {{ toast.message.title }} </p>
              <component v-if="typeof toast.message?.body !== 'string'" :is="toast.message.body" />
              <p v-else class="mt-1 text-sm text-gray-500"> {{ toast.message.body }} </p>
            </div>

            <div class="ml-4 flex-shrink-0 flex">
              <button class="
                  bg-white
                  rounded-md
                  inline-flex
                  text-gray-400
                  hover:text-gray-500
                  focus:outline-none
                  focus:ring-2 focus:ring-offset-2 focus:ring-primary-600
                " :class="`bg-${alertType.style}-100`" @click="close">
                <span class="sr-only">Close</span>
                <XIcon v-if="!toast.timeout || toast.timeout === -1" class="h-5 w-5 bg-transparent"
                  aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import type { PropType } from 'vue';
import { defineComponent } from 'vue';
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  QuestionMarkCircleIcon,
  InformationCircleIcon,
  XCircleIcon,
} from '@heroicons/vue/outline';
import { XIcon } from '@heroicons/vue/solid';
import type { Alert, dismissAlert } from '../../services/toastService';
import { AlertType } from '../../services/toastService';

interface ToastType {
  is: unknown
  style: 'red' | 'yellow' | 'orange' | 'blue' | 'green'
}

export default defineComponent({
  components: {
    CheckCircleIcon,
    XIcon,
  },
  props: {
    toast: {
      type: Object as PropType<Alert>,
      required: true,
    },
  },
  emits: ['close'],
  data() {
    return {
      show: !!this.toast,
    };
  },
  computed: {
    alertType(): ToastType {
      let component: ToastType;

      switch (this.toast.type) {
        case AlertType.INFO:
          component = {
            is: InformationCircleIcon,
            style: 'blue',
          };
          break;
        case AlertType.URGENT:
          component = {
            is: ExclamationCircleIcon,
            style: 'orange',
          };
          break;
        case AlertType.WARNING:
          component = {
            is: QuestionMarkCircleIcon,
            style: 'yellow',
          };
          break;
        case AlertType.CRITICAL:
          component = {
            is: XCircleIcon,
            style: 'red',
          };
          break;
        default:
          component = {
            is: CheckCircleIcon,
            style: 'green',
          };
          break;
      }
      return component;
    },
  },
  mounted() {
    if (this.toast.timeout && this.toast.timeout !== -1) {
      setTimeout(() => this.close(), this.toast?.timeout ?? 5000);
    }
  },
  methods: {
    close() {
      this.show = false;
      this.$emit('close');
    },
  },
});
</script>
