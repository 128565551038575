import type { ComputedRef } from 'vue';
import { ref, computed } from 'vue';

interface Stepper {
  current: ComputedRef<number>;
  prev: ComputedRef<number>;
  next: ComputedRef<number>;
  goToPrev: () => boolean;
  goToNext: () => boolean;
  setStep: (step: number) => void;
}

type StepperOptions = {
  start?: number;
  max?: number;
};

export const useStepper = (options: StepperOptions = {}): Stepper => {
  const { start = 1, max = 1 } = options;

  const step = ref(start);
  const prev = computed(() => step.value - 1);
  const next = computed(() => step.value + 1);

  const goToPrev = () => {
    if (step.value < 2) return false;
    step.value--;
    return true;
  };

  const goToNext = () => {
    if (Number.isInteger(max) && step.value >= max) return false;
    step.value++;
    return true;
  };

  const setStep = (value: number) => {
    if (!Number.isInteger(step)) throw new TypeError('A step value must be an integer.');
    step.value = value;
  };

  return {
    current: computed(() => step.value),
    prev,
    next,
    goToPrev,
    goToNext,
    setStep,
  };
};
