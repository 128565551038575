<template>
  <div class="absolute inset-y-0 left-0 md:static md:flex-shrink-0" :style="isStaging ? 'filter: sepia(1)' : ''">
    <a href="#" class="
        flex
        items-center
        justify-center
        w-16
        h-16
        bg-primary-600
        rounded-none
        md:w-20
      " :style="isMacOS ? { height: '78px' } : ''">
      <img class="h-8 w-auto" :src="logo" alt="Workflow">
      <!-- src="https://tailwindui.com/img/logos/workflow-mark.svg?color=white" -->
    </a>
  </div>
</template>

<script setup lang="ts">
import logo from '@assets/images/push-pass-logo-white.svg';
const isMacOS = window?.electron?.platform === 'darwin';
const isStaging = window?.apiServerURL.includes('staging');
</script>

<style></style>
