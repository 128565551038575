<template>
  <Container>
    <Section v-if="!metricsLoading" heading="Active Projects" :level="4" weight="font-medium">
      <StatsPanel :items="stats" />
    </Section>

    <Section>
      <BaseCard>
        <StackedBarChart :filter="{}" :loading="statsLoading" :data="agentData?.agentStats ?? []" />
      </BaseCard>
    </Section>

    <Section>
      <BaseCard>
        <OrdersPerDay v-if="!metricsLoading && projectMetrics().ordersCompleted"
          :data="projectMetrics().ordersCompleted" :options="{
      labelName: 'Completed Count',
      backgroundColor: '#14CC60',
      barThickness: 10,
      borderRadius: 50
    }" graphTitle="Completed Orders Per Day" />
      </BaseCard>
    </Section>

    <Section>
      <BaseCard>
        <OrdersPerDay v-if="!metricsLoading && projectMetrics().ordersAssigned" :data="projectMetrics().ordersAssigned"
          :options="{
      labelName: 'Assigned Count',
      backgroundColor: '#EB8A90',
      barThickness: 10,
      borderRadius: 50
    }" graphTitle="Assigned Orders Per Day" />
      </BaseCard>
    </Section>

  </Container>
</template>

<script setup lang="ts">
import type { GetAgentStatsQuery } from "@api/graphql/types/graphql";
import type { StatItem } from "@components/base/types";

import { ref } from "vue";
import { useQuery } from "@vue/apollo-composable";
import { computed } from "@vue/reactivity";
import { getData, setData } from "@utils/localStorage";
import { DocumentTextIcon } from "@heroicons/vue/outline";

import { GET_PROJECT_METRICS, GET_AGENT_STATS } from '@graphql/project/queries'
import BaseCard from "@components/base/BaseCard.vue";
import Container from "@components/common/Container.vue";
import Section from "@components/common/Section.vue";
import OrdersPerDay from "@components/common/Graphs/OrdersPerDay.vue";
import StackedBarChart from "@components/common/Graphs/StackedBarChart.vue";

import StatsPanel from "./components/StatsPanel.vue";



const cachedData = await getData('dashboard.projectMetrics')
const { result, loading: metricsLoading } = useQuery(
  GET_PROJECT_METRICS,
  null,
  !cachedData ? { fetchPolicy: "no-cache" } : {}
);

const { result: agentData, loading: statsLoading } = useQuery<GetAgentStatsQuery>(
  GET_AGENT_STATS,
  null,
  { fetchPolicy: "no-cache" }
);

const projectMetrics = () => {
  if (cachedData) return cachedData;
  setData('dashboard.projectMetrics', result.value?.projectMetrics ?? undefined);
  return result.value?.projectMetrics ?? []
};

const averageCompleted = ref(0)
const averageAssigned = ref(0);
const formattedDates: string[] = []
// calendar view @ gist d74fa073c0c58d3112ccb871a180fc9017ceabf6

const stats = computed(() => [
  {
    id: 1,
    name: `Total Orders YTD`,
    stat: result.value?.projectMetrics.totalOrders,
    icon: DocumentTextIcon,
  },
  // {
  //   id: 2,
  //   name: `Average Assigned By Date (${formattedDates[0] ?? '?'}-${formattedDates.at(-1) ?? '?'})`,
  //   stat: Math.floor(averageAssigned.value),
  //   icon: ArchiveIcon,
  // },
  // {
  //   id: 3,
  //   name: `Average Completed By Date (${formattedDates[0] ?? '?'}-${formattedDates.at(-1)?? '?'})`,
  //   stat: Math.floor(averageCompleted.value),
  //   icon: TruckIcon,
  // },
] as StatItem[]);

</script>
