import gql from 'graphql-tag';
import { ORDER_STATUSES } from '../fragments/ORDER_STATUSES';

export const GET_PROJECT_STATS = gql`
  query GetProjectStats($filter: ProjectStatsFilter, $sort: SortOrder) {
    projectStats(filter: $filter, sort: $sort) {
      id
      project {
        name
        startDate
        endDate
        eventDate
      }
      statuses {
        ...OrderStatuses
      }
      totalOrders
    }
  }

  ${ORDER_STATUSES}
`;
