import { createUser } from '../models/User';
import { AuthGroup } from '../models/User';

export const people: User[] = [
  createUser({
    id: '1',
    roles: [AuthGroup.ADMIN],
    role: '1234',
    username: 'JCooper',
    isActive: true,
    person: {
      id: '1',
      firstName: 'Jane',
      lastName: 'Cooper',
      title: 'Operations Manager',
      email: 'janecooper@example.com',
      phone: '+1-202-555-0170',
      imageUrl: null,
    },
  }),
  createUser({
    id: '2',
    username: 'JMacdonald',
    roles: [AuthGroup.AGENT],
    role: '1234',
    isActive: true,
    person: {
      id: '2',
      firstName: 'Joe',
      lastName: 'Macdonald',
      title: 'FulFillment Agent',
      email: 'janecooper@example.com',
      phone: '+1-202-555-0170',
      imageUrl: null,
    },
  }),
  createUser({
    id: '3',
    username: 'PGuy',
    roles: [AuthGroup.GUEST],
    role: '1234',
    isActive: true,
    person: {
      id: '3',
      firstName: 'Peter',
      lastName: 'Guy',
      title: 'Event Manager',
      email: 'janecooper@example.com',
      phone: '+1-202-555-0170',
      imageUrl: null,
    },
  }),
];
