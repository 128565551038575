import type { RouteLocationRaw, RouteRecordRaw } from 'vue-router';
import { isAdmin } from '../../composables/useAuth';

export const FulfillmentRoutes: Array<RouteRecordRaw> = [
  {
    path: 'fulfillment',
    name: 'fulfillment',
    component: () => import('./Fulfillment.vue'),
    beforeEnter: (): RouteLocationRaw => {
      if (!isAdmin.value) {
        return { name: 'fulfillment:agent' };
      } else {
        return { name: 'fulfillment:admin' };
      }
    },
  },
  {
    path: 'fulfillment',
    name: 'fulfillment:admin',
    component: () => import('./admin/FulfillmentAdminOverview.vue'),
  },
  {
    path: 'fulfillment/admin/schedule',
    name: 'fulfillment:schedule',
    component: () => import('./admin/FulfillmentAdminScheduling.vue'),
  },
  {
    path: 'fulfillment',
    name: 'fulfillment:agent',
    props: true,
    component: () => import('./agent/FulfillmentAgentOverview.vue'),
  },
  {
    path: 'fulfillment/:taskId',
    name: 'fulfillment:task',
    props: true,
    component: () => import('./agent/FulfillmentTask.vue'),
  },
];
