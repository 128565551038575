import type { App } from 'vue';

export const registerBaseComponents = async (vm: App): Promise<void> => {
  try {
    console.debug('Auto Loading Global Components');

    // Require base component context
    const baseComponents = import.meta.glob('../components/base/**/*.vue');
    const commonComponents = import.meta.glob('../components/common/**/*.vue');

    const globalComponents = { ...baseComponents, ...commonComponents };
    for (const filePath in globalComponents) {
      // Get component config
      const componentConfig = (await globalComponents[filePath]()).default;
      // Get filename from the filePath
      const fileName = filePath.split('/').slice(-1)[0];
      // Remove file extension and convert component name to pascal case
      const componentName = fileName.replace(/\.\w+$/, '');

      // console.debug(`Component: ${componentName}`)

      // Register component globally
      vm.component(componentName, componentConfig);
    }
  } catch (err) {
    console.debug('Base component registration failed');
    console.error(err);
  }
};
