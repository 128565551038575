import gql from 'graphql-tag';

export const PRODUCT_FULL_FRAGMENT = gql`
  fragment ProductFullDetails on Product {
    id
    name
    shortName
    externalId
    orderLimit
    scanType
    hasScanData
    imageUrl
    weightOz
    inventoryTotal
    customsSku
    customsHsCode
    customsValue
    customsDescription
    customsOriginCountry
  }
`;
