import type { RouteLocationNormalized, RouteRecordRaw } from 'vue-router';

import UsersProvider from './UserProvider.vue';

export const UserRoutes: Array<RouteRecordRaw> = [
  {
    path: 'users',
    component: UsersProvider,
    children: [
      {
        path: '/users',
        name: 'users',
        component: () => import('./Users.vue'),
      },
      {
        path: 'user/:id',
        name: 'user:profile',
        props: true,
        beforeEnter: (to: RouteLocationNormalized): boolean => {
          if (!to.params.id) return false;
          return true;
        },
        component: () => import('./UserProfile.vue'),
      },
      {
        path: '/groups',
        name: 'groups',
        component: () => import('./Groups.vue'),
      },
      {
        path: '/group/create',
        name: 'group:create',
        component: () => import('./GroupCreate.vue'),
      },
      {
        path: 'group/:id',
        name: 'group:detail',
        props: true,
        beforeEnter: (to: RouteLocationNormalized): boolean => {
          if (!to.params.id) return false;
          return true;
        },
        component: () => import('./GroupDetail.vue'),
      },
      {
        path: '/companies',
        name: 'companies',
        component: () => import('./Companies.vue'),
      },
      // {
      //   path: 'companies/:id',
      //   name: 'companies:profile',
      //   props: true,
      //   beforeEnter: (to: RouteLocationNormalized): boolean => {
      //     if (!to.params.id) return false;
      //     return true;
      //   },
      //   component: () => import('./CompanyProfile.vue'),
      // },
    ],
  },
];
