import gql from 'graphql-tag';

export const GET_SHIPMENTS = gql`
  query GetShipments(
    $projectId: ID!
    $sort: [SortOrder]
    $limit: Int
    $before: String
    $after: String
    $where: [WhereFilter]
  ) {
    shipments(projectId: $projectId, limit: $limit, sort: $sort, after: $after, before: $before, where: $where) {
      meta {
        totalRecords
        count
      }
      nodes {
        id
        project {
          id
          name
        }
        orderId
        toAddress {
          contactName
          street1
          street2
          city
          state
          postalCode
          country
        }
        carrier {
          id
          name
        }
        service
        shippedOn
        trackingCode
        trackerRefId
        trackerUrl
        parcel {
          height
          length
          width
          weight
        }
        postageLabel {
          size
          labelUrl
          resolution
          generatedOn
        }
        status
      }
    }
  }
`;
