import gql from 'graphql-tag';

export const GET_SHIPMENTS_SETTINGS = gql`
  query GetShipSettings($projectId: ID!, $where: [WhereFilter]) {
    shipmentSettings(projectId: $projectId, where: $where) {
      id
      name
      priority
      color
      addOnItems
      rules {
        condition
        values
        field
      }
      shipMethod {
        id
        name
        service
        carrier {
          id
          name
          shippingService
        }
      }
    }
  }
`;
