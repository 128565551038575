interface Options {
  public?: boolean;
  token?: string | null;
}

export interface GraphAPIClient {
  query<QueryResult>(query: string, opts?: Options): Promise<QueryResult>;
  mutation<MutationResult>(mutation: string, variables: unknown, opts?: Options): Promise<MutationResult>;
}

export const GraphAPIClient = (url: string): GraphAPIClient => {
  if (!url) throw new Error('GraphQL client requires a full URL to the Graphql endpoint');

  const GRAPH_URL = url;
  console.debug('[Fetch API Client] GraphQL client using URL', GRAPH_URL);

  return {
    async query(query: string, opts: Options = {}) {
      const myHeaders = new Headers();

      let auth;
      if (!opts.public) {
        if (!opts.token) {
          console.error('Unable to make call without token. Please provide token in options.');
          return;
        }
        auth = `Bearer ${opts.token}`;
        myHeaders.append('Authorization', auth);
      }

      myHeaders.append('Content-Type', 'application/json');

      const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify({ query, variables: {} }),
        redirect: 'follow',
      } as RequestInit;

      try {
        const response = await fetch(GRAPH_URL, requestOptions);
        const data = await response.json();

        if (!data) throw new Error('Network Problems');
        return data;
      } catch (err) {
        console.error(err);
      }
    },
    async mutation(mutation: string, variables: unknown = {}, opts: Options = {}) {
      const myHeaders = new Headers();

      let auth;
      if (!opts.public) {
        if (!opts.token) {
          console.error('Unable to make call without token. Please provide token in options.');
          return;
        }
        auth = `Bearer ${opts.token}`;
        myHeaders.append('Authorization', auth);
      }
      myHeaders.append('Content-Type', 'application/json');

      const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify({ query: mutation, variables }),
        redirect: 'follow',
      } as RequestInit;

      try {
        const response = await fetch(GRAPH_URL, requestOptions);
        const data = await response.json();

        if (!data) throw new Error('Network Problems');
        return data;
      } catch (err) {
        console.error(err);
      }
    },
  };
};
