import type { JwtPayload } from 'jwt-decode';
import type { User, RefreshTokenResult, LoginMutationResult } from './interfaces/auth.types';
import JwtDecode from 'jwt-decode';
import * as Sentry from '@sentry/electron/renderer';

import { GraphAPIClient } from '../fetch/client';

// @ts-expect-error in Electron env only 'apiServerURL' is available at this time otherwise use static env.VITE_API_URL
const BASE_URL = globalThis.apiServerURL || import.meta.env.VITE_API_URL || 'http://localhost:9000';
const GRAPH_URL = new URL('/graphql', BASE_URL).toString();
const graphQL = GraphAPIClient(GRAPH_URL);

export const loginWithCredentials = async (username: string, password: string) => {
  const LOGIN_MUTATION = `
    mutation login ($data: LoginUserInput!) {
      login (data: $data) {
        payload {
          type
          refresh_token
          access_token
        }
      }
    }`;

  try {
    Sentry.setUser({ username: username }); // Add user context to Sentry events

    const { data, errors } = await graphQL.mutation<LoginMutationResult>(
      LOGIN_MUTATION,
      { data: { username, password } },
      { public: true }
    );

    if (errors) throw errors;

    if (!data) {
      throw new Error('Error Logging in. The Authentication server maybe down.');
    }

    return { payload: data.login.payload, errors: null };
  } catch (errors) {
    console.error(errors);
    return { errors: errors as Error[], payload: null };
  }
};

let refreshing = false;
export const refreshToken = async (uri: string, token: string) => {
  const REFRESH_TOKEN = `
    mutation RefreshToken($token: String! ) {
      refresh(token: $token) {
        payload {
          access_token
          refresh_token
          type
        }
      }
    }`;

  refreshing = true;

  try {
    const { data, errors } = await graphQL.mutation<RefreshTokenResult>(REFRESH_TOKEN, { token }, { public: true });

    if (errors) throw errors;
    if (!data) throw new Error('Error refreshing token');

    console.debug(data);
    return data?.refresh?.payload?.access_token;
  } catch (err) {
    console.error(err);
    throw new Error('Error refreshing token');
  } finally {
    refreshing = false;
  }
};

const _updateToken = async (url: string): Promise<boolean> => {
  console.debug('Get Refresh Token');
  const token = localStorage.getItem('refresh_token');
  if (!token) return false;
  const newToken = await refreshToken(url, token);

  newToken && localStorage.setItem('access_token', newToken);
  return true;
};

const TEN_MINUTES_BEFORE_EXPIRY = 600; // in seconds

export const getAccessToken = (url?: string): string | null => {
  let token;

  if (typeof Storage !== 'undefined') {
    token = localStorage.getItem('access_token');
  }

  if (!token) {
    console.warn('No access token found');
    return null;
  }

  const { iat = 0, exp = 0 } = JwtDecode(token) as JwtPayload;
  console.debug(
    '[TOKEN] Issued:',
    iat > 0 && new Date(iat * 1000).toLocaleString(),
    'Expiration:',
    exp > 0 && new Date(exp * 1000).toLocaleString()
  );

  if (url) {
    const currentTimeInSeconds = new Date().getTime() / 1000;
    const refreshTimeInSeconds = Math.max(exp - TEN_MINUTES_BEFORE_EXPIRY, 0);

    if (currentTimeInSeconds > refreshTimeInSeconds && !refreshing) {
      _updateToken(url); // try refreshing token
      token = localStorage.getItem('access_token');
    }
  }

  return token;
};

export const logoutUser = async () => {
  const LOGOUT_MUTATION = `
  mutation Logout {
    logout
  }
`;

  let token;
  if (typeof Storage !== 'undefined') {
    token = localStorage.getItem('access_token');
  }

  if (!token) {
    console.warn('No access token found');
    return null;
  }

  try {
    const result = await graphQL.mutation<boolean>(LOGOUT_MUTATION, { token });
    return result;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const resolveUser = async (token: string) => {
  const FETCH_ME = `
    query FetchMe {
      me {
        id
        group {
          id
          name
        }
        username
        type
        isSuperAdmin
        person {
          email
          firstName
          lastName
          title
          imageUrl
        }
        deletedOn
        createdAt
        updatedAt
      }
    }`;

  try {
    const {
      data: { me },
    } = await graphQL.query<{ data: { me: User } }>(FETCH_ME, { token });
    return me;
  } catch (err) {
    console.error(err);
    return {} as User;
  }
};

export default {
  resolveUser,
  refreshToken,
  getAccessToken,
};
