import type { RouteRecordRaw } from 'vue-router';

export const ProjectRoutes: Array<RouteRecordRaw> = [
  {
    path: '',
    component: () => import('./Projects.vue'),
    meta: { requiresAuth: true, readOnly: false },
    children: [
      {
        path: 'projects',
        name: 'projects',
        component: () => import('./ProjectList.vue'),
      },
      {
        path: ':projectId/details',
        name: 'edit-project',
        props: true,
        component: () => import('./ProjectDetails.vue'),
      },
      {
        path: 'projects/:projectId',
        // name: 'view-project',
        props: true,
        component: () => import('./ProjectOverview.vue'),
        children: [
          {
            path: '',
            props: true,
            name: 'project:dashboard',
            component: () => import('./components/ProjectDash.vue'),
          },
          {
            path: 'orders',
            props: true,
            name: 'project:orders',
            component: () => import('./components/orders-table/OrdersTable.vue'),
          },
          {
            path: 'fulfillment',
            props: true,
            name: 'project:fulfillment',
            component: () => import('./components/fulfillment-uoc/FulfillmentTable.vue'),
          },
          {
            path: 'tickets',
            props: true,
            name: 'project:tickets',
            component: () => import('./components/TicketsTable.vue'),
          },
          {
            path: 'shipping',
            name: 'project:shipping',
            props: true,
            component: () => import('./components/shipment-setting/ShipSettingsTable.vue'),
          },
          {
            path: 'shipments',
            name: 'project:shipments',
            props: true,
            component: () => import('./components/shipments-table/ShipmentsTable.vue'),
          },
          {
            path: 'products',
            props: true,
            name: 'project:products',
            component: () => import('./components/ProductsTable.vue'),
          },
          {
            path: 'email',
            props: true,
            name: 'project:email',
            component: () => import('./components/email/Email.vue'),
          },
          {
            path: 'settings',
            props: true,
            name: 'project:settings',
            component: () => import('./components/SettingsForm.vue'),
          },
        ],
      },
      {
        path: 'projects/:projectId/products/:productId',
        props: true,
        name: 'project:product',
        component: () => import('./components/ProductDetailsForm.vue'),
      },
      {
        path: 'projects/:projectId/tickets/:ticketId',
        props: true,
        name: 'project:ticket:mappings',
        component: () => import('./components/TicketMappingForm.vue'),
      },
      {
        path: 'projects/:projectId/orders/:orderId',
        props: true,
        name: 'project:orders:order',
        component: () => import('./components/order-details/OrderDetails.vue'),
      },
      {
        path: 'projects/:projectId/shipsettings/:settingId',
        props: true,
        name: 'project:setting:form',
        component: () => import('./components/shipment-setting/ShipSettingDetails.vue'),
      },
    ],
  },
];
