import gql from 'graphql-tag';

export const GET_TICKET = gql`
  query GetTicketByID($id: ID!) {
    ticket(id: $id) {
      id
      name
      referenceId
      mappedItems {
        id
        quantity
        product {
          id
          name
          imageUrl
        }
      }
      isMapped
    }
  }
`;
