import gql from 'graphql-tag';

export const GET_UOC_TABLE_DATA = gql`
  query GetUOCTableData($projectId: ID!, $where: [WhereFilter]) {
    fulfillmentQueueStats(projectId: $projectId, where: $where) {
      id
      name
      total
      expectedShipDate
      statuses {
        created
        verified
        verifying
        failed
        cancelling
        cancelled
        queued
        preparing
        prepared
        scheduling
        scheduled
        fulfilling
        completing
        completed
        shipping
        shipped
      }
    }
  }
`;
