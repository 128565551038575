import gql from 'graphql-tag';

export const GET_SHIPMENTS_SETTING = gql`
  query GetShipSettingById($id: ID!) {
    shipmentSetting(id: $id) {
      id
      name
      priority
      addOnItems
      shipMethod {
        id
        name
        service
        carrier {
          id
          name
          shippingService
        }
      }
    }
  }
`;
