import { gql } from 'graphql-tag';

export const GET_AGENT_STATS = gql`
  query GetAgentStats($projectId: ID) {
    agentStats(projectId: $projectId) {
      id
      project {
        id
        name
      }
      agent {
        id
        person {
          firstName
          lastName
        }
      }
      count
      orders
      date
    }
    agentBreakdown(projectId: $projectId) {
      id
      project {
        id
        name
      }
      agent {
        id
        person {
          firstName
          lastName
        }
      }
      count
      orders
    }
  }
`;
