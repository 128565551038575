import gql from 'graphql-tag';
import { COMPANY_INFO } from '../fragments/COMPANY_INFO';

export const GET_PROJECT_WITH_USERS = gql`
  query GetProjectByIdWithUsers($id: ID!, $where: [WhereFilter], $sort: [SortOrder]) {
    project(id: $id) {
      id
      name
      startDate
      eventDate
      endDate
      serviceToken
      emails {
        invalidOrderAddress {
          id
          name
        }
        shipOrderConfirmation {
          id
          name
        }
        finalHoldAtWillcall {
          id
          name
        }
      }
      defaultReturnAddress {
        id
        companyName
        contactName
        street1
        street2
        street3
        phone
        city
        state
        postalCode
        country
      }
      gateCompany {
        id
        name
        ...CompanyInfo
      }
      ticketCompany {
        id
        name
        ...CompanyInfo
      }
      members {
        id
      }
    }
    users(where: $where, sort: $sort) {
      id
      group {
        id
        name
      }
      person {
        email
        firstName
        lastName
      }
      isActive
      createdAt
      updatedAt
      deletedOn
    }
    apiConsumers {
      meta {
        totalRecords
        count
      }
      nodes {
        id
        isActive
        name: applicationName
        email: applicationEmail
        group {
          id
          name
        }
        createdAt
        updatedAt
      }
    }
  }

  ${COMPANY_INFO}
`;
