import gql from 'graphql-tag';

export const GET_AGENT_ORDER = gql`
  query GetAgentOrderByID($id: ID!) {
    order(id: $id) {
      id
      status
      createdAt
      clientOrderId
      address {
        errorReports {
          field
          message
        }
        verified
        contactName
        street1
        street2
        city
        state
        postalCode
        country
        phone
        email
        type
      }
      lineItems {
        id
        product {
          id
          name
          weightOz
          imageUrl
          scanType
        }
        scanValue
        status
      }
    }
  }
`;
