import { sendAlert, dismissAll, AlertType } from '@services/toastService';

if (window) {
  window.addEventListener('offline', () => {
    sendAlert({
      type: AlertType.WARNING,
      message: {
        title: 'Not Online',
        body: '',
      },
    });
  });

  window.addEventListener('online', () => {
    dismissAll();
    sendAlert({
      type: AlertType.SUCCESS,
      message: {
        title: 'Back Online',
        body: '',
      },
      timeout: 5000,
    });
  });
}

export default {};
