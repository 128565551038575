import gql from 'graphql-tag';

export const GET_PRODUCTS = gql`
  query Products($projectId: ID!, $where: [WhereFilter]) {
    products(projectId: $projectId, where: $where) {
      id
      name
      shortName
      scanType
      mustMatchExactly
      inventoryTotal
      shipped: inventoryTotal
      weightOz
      externalId
      hasScanData
    }
  }
`;
