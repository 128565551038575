import gql from 'graphql-tag';

export const GET_PRODUCT = gql`
  query GetProductById($id: ID!) {
    product(id: $id) {
      id
      name
      shortName
      scanType
      mustMatchExactly
      weightOz
      orderLimit
      externalId
      inventoryTotal
      customsSku
      customsDescription
      customsValue
      customsHsCode
      customsOriginCountry
      hasScanData
      imageUrl
    }
  }
`;
