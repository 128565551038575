<template>
  <Section>
    <Form title="Server Configuration" description="Add central server configuration">
      <GridGroup>
        <GridField title="Server URI" border col-span="2" placeholder="ex. http(s)://localhost:9000"
          :value="configs?.server?.uri ?? ''" @change="handleChange" />
      </GridGroup>

      <div class="flex px-10 pb-8 sm:justify-end">
        <BaseButton class="w-full sm:w-auto shadow transition-all ease-out duration-200" :type="buttonType"
          @click="testConnection">Test Connection</BaseButton>
      </div>
    </Form>

    <div class="flex my-4 justify-end">
      <BaseButton size="md" @click="submit">Next</BaseButton>
    </div>
  </Section>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';
import { AlertType, sendAlert } from '@services/toastService';
import { useStateProvider } from '../provider/SetupStateProvider.vue';
import BaseButton, { ButtonStyleType } from '@components/base/BaseButton.vue';

export default defineComponent({
  setup() {
    const { current, goToNext, updateStatus } = useStateProvider();
    const { push } = useRouter();
    const buttonType = ref(ButtonStyleType.INFO);

    const isValid = (value: string) => {
      try {
        new URL(value.trim());
        return true;
      } catch (err) {
        console.error(err);
        return false;
      }

    };

    const testConnection = async () => {
      try {
        const url = new URL(window.apiServerURL + 'health').toString();
        console.debug(url);
        const response = await fetch(url, { headers: { 'Access-Control-Allow-Origin': '*', origin: 'http://app.idc.test:3000' } });

        if (response.ok) {
          console.debug(`called ${url}`, response);
          alert(
            AlertType.SUCCESS,
            'Success',
            `Connected to ${window.apiServerURL}`,
          );
          buttonType.value = ButtonStyleType.SUCCESS;
        }

      } catch (err) {
        console.error(err);
        buttonType.value = ButtonStyleType.DANGER;
        setTimeout(() => buttonType.value = ButtonStyleType.INFO, 2000);
        alert(
          AlertType.CRITICAL,
          'Invalid Configs',
          'Failed to reach server. Please check you configurations and try again.',
        );
      }
    };

    const handleChange = (event: Event) => {
      const target = event.target as HTMLInputElement;
      if (isValid(target.value)) {
        console.debug('Save to local electron configs and browser storage');
        window.localConfigs.setItem('server.uri', new URL(target.value.trim()).toString());
        window.electronStoreConfigs.setItem('server.uri', new URL(target.value.trim()).toString());
      } else {
        alert(
          AlertType.WARNING,
          'Bad URL',
          'This is not a correct URL. Please try again',
        );
      }
    };

    const submit = async () => {
      if (window.apiServerURL) {
        updateStatus(current, 'complete');
        goToNext() && push({ name: 'setup:step-two' });
      } else {
        alert(
          AlertType.WARNING,
          'Invalid Configs',
          'This configuration is incorrect. Please try again',
        );
      }
    };

    const alert = (type: AlertType, title: string, message: string) => {
      sendAlert({
        type,
        message: {
          title: title,
          body: message,
        },
        timeout: 3000,
      });
    };

    return {
      configs: window.electronStoreConfigs,
      submit,
      buttonType,
      handleChange,
      testConnection,
    };
  }
});
</script>
