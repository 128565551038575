<template>
  <!-- v-slot="{ steps, current }" -->
  <FullPageBlank class="p-10 bg-gray-100">
    <Container>
      <SetupStateProvider :initial-state="setupSteps">
        <SetupMultiStepForm />
      </SetupStateProvider>
    </Container>
  </FullPageBlank>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import type { MultiFormStep } from '@components/common/MultiStepForm.vue';
import FullPageBlank from '@layouts/FullPageBlank.vue';
import SetupStateProvider from './provider/SetupStateProvider.vue';
import SetupMultiStepForm from './components/SetupMultiStep.vue';

const setupSteps: MultiFormStep[] = [
  {
    id: 1,
    name: 'Step 1',
    description: 'Configure Application Server',
    to: { name: 'setup:step-one' },
    status: 'incomplete',
  },
  {
    id: 2,
    name: 'Step 2',
    description: 'Register Admin User',
    to: { name: 'setup:step-two' },
    status: 'incomplete',
  },
  {
    id: 3,
    name: 'Step 3',
    description: 'Create Project',
    to: { name: 'setup:step-three' },
    status: 'incomplete',
  },
];

export default defineComponent({
  components: { FullPageBlank, SetupStateProvider, SetupMultiStepForm },
  setup() {
    return {
      setupSteps,
    };
  },
});
</script>
