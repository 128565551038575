<template>
  <div class="flex w-full justify-end bg-white">
    <!-- Logo area -->
    <slot name="logo" />
    <!-- Picker area -->
    <slot name="mobileNav" />
    <!-- Menu button area -->
    <slot name="Menu" />
    <!-- Desktop nav area -->
    <div
      class="
        bg-white md:min-w-0 md:flex-1 md:flex md:items-center md:justify-end
      "
    >
      <div class="mx-4 flex items-center sm:pr-6">
        <slot name="headerLeft" />
      </div>
      <div class="relative ml-10 pr-4 flex-shrink-0 flex items-center space-x-10">
        <!-- Context Menu List -->
        <slot name="contextActions">
          <!-- <nav aria-label="Global" class="flex space-x-10">
            <a href="#" class="text-sm font-medium text-gray-900">Inboxes</a>
            <a href="#" class="text-sm font-medium text-gray-900">Reporting</a>
            <a href="#" class="text-sm font-medium text-gray-900">Settings</a>
          </nav> -->
        </slot>
        <!-- Notifications Action Menu -->
        <div class="flex items-center space-x-8">
          <slot name="actionsMenus" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  emits: {
    click: null,
  },
  setup() {
    return {
    };
  },
});
</script>

<style></style>
