import type { Ref } from 'vue';
import { ref } from 'vue';

interface Toggler {
  state: Ref<boolean>;
  open: () => void;
  close: () => void;
  toggle: () => void;
}

export const useToggleState = (defaultValue = false): Toggler => {
  const state = ref(defaultValue);
  const open = () => (state.value = true);
  const close = () => (state.value = false);
  const toggle = () => (state.value = !state.value);

  return {
    state,
    open,
    close,
    toggle,
  };
};
