import gql from 'graphql-tag';

export const GET_ORDERS_BY_SETTING = gql`
  query GetOrdersBySetting($projectId: ID!) {
    ordersBySetting(projectId: $projectId) {
      id
      setting {
        name
      }
      totalOrders
      statuses {
        cancelled
        cancelling
        completed
        completing
        created
        failed
        failed_address
        failed_settings
        fulfilling
        queued
        scheduled
        shipped
        shipping
        verified
        verifying
      }
    }
  }
`;
