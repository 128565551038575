<template>
  <Menu as="div" class="relative inline-block text-left">
    <MenuButton
      class="bg-white rounded-full flex text-sm items-center border-l border-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-600">
      <span class="sr-only">Open user menu</span>
      <img class="h-8 w-8 rounded-full" :src="avatarImg" :alt="user.username" />
      <span class="pl-3 text-lg text-gray-600" id="header-user-dropdown">{{ user.username }}</span>
    </MenuButton>

    <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
      <MenuItems
        class="origin-top-right absolute z-50 right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
        <div class="py-1 text-left text-sm text-gray-700">

          <MenuItem v-if="isAdmin" v-slot="{ active }" id="userProfile">
          <RouterLink :to="{ name: 'user:profile', params: { id: user.id } }"
            class="block px-4 py-2 text-sm text-gray-700" :class="[active ? 'bg-gray-100' : '']">
            Profile
          </RouterLink>
          </MenuItem>

          <MenuItem v-if="isAdmin" v-slot="{ active }">
          <RouterLink :to="{ name: 'settings' }" class="block px-4 py-2 text-sm text-gray-700"
            :class="[active ? 'bg-gray-100' : '']">
            Admin Settings
          </RouterLink>
          </MenuItem>

          <MenuItem v-slot="{ active }">
          <button class="block w-full px-4 py-2 text-left" :class="{ 'bg-gray-100': active }" @click="signOut">
            Sign Out
          </button>
          </MenuItem>
        </div>
      </MenuItems>
    </transition>
  </Menu>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import { useRouter } from 'vue-router';
import { useAuth } from '@composables/useAuth';
import avatar from '@assets/images/avatar.png';

const { push } = useRouter();
const { logout, isAdmin, user } = useAuth();
const emits = defineEmits(['loggedOut']);

const avatarImg = computed(() => {
  return (user && user.value?.person?.['imageUrl'])
    ? user.value.person?.imageUrl
    : avatar;
});

const signOut = async () => {
  emits('loggedOut');
  await logout();
  push({ name: 'login', params: { hasTimedOut: 'false' }, replace: true });
};
</script>
