<template>
  <LoaderOverlayProvider>

    <AppContainer>
      <template #header>
        <AppHeader :logo="HeaderLogo" :mobile-nav="sidebarNav" @click="toggleMenu">
          <template #actions>
            <!-- <span class="inline-flex">
            <button
              class="-mx-1 bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-600"
              @click="openNotifications"
            >
              <span class="sr-only">View notifications</span>
              <BellIcon class="h-6 w-6" aria-hidden="true" />
            </button>
          </span> -->

            <!-- User Dropdown Menu -->
            <AvatarMenu />

            <BaseSideOver title="Notifications" :open="isSideOpen" @close="closeNotifications" />
          </template>
        </AppHeader>
      </template>

      <template #sidenav>
        <BaseSideMenu :items="sidebarNav" :is-open="isMenuOpen" @toggle="toggleMenu" />
      </template>

      <!-- Content Area -->
      <router-view />
      <!-- End Content Area -->
    </AppContainer>

  </LoaderOverlayProvider>
</template>

<script setup lang="ts">
import type { NavItem } from "@components/base/types";

import { ChartPieIcon, ArchiveIcon, BriefcaseIcon, TruckIcon, AdjustmentsIcon } from "@heroicons/vue/solid";
// import { BellIcon } from "@heroicons/vue/outline";
import AppContainer from "@layouts/AppContainer.vue";
import BaseSideOver from "@components/base/BaseSideOver.vue";
import BaseSideMenu from "@components/base/BaseSideMenu.vue";
import LoaderOverlayProvider from '@components/common/LoaderOverlayProvider.vue'
import AvatarMenu from "@components/common/AvatarMenu.vue";
import AppHeader from "@components/common/AppHeader.vue";
import HeaderLogo from "@components/common/HeaderLogo.vue"
import { createApolloClient } from "@api/graphql/client";
import { useToggleState } from "@composables/useToggleState";
import { isAdmin } from "@composables/useAuth";

import '@services/onlineService';

// Register our Apollo Client Provider to the app branch
const client = await createApolloClient(new URL('/graphql', window.apiServerURL).toString());
client.provider();

const { state: isMenuOpen, toggle: toggleMenu } = useToggleState(false);
const { state: isSideOpen, open: openNotifications, close: closeNotifications } = useToggleState(false);

const sidebarNav: NavItem[] = [
  {
    name: "Dashboard",
    page: { name: "dashboard" },
    icon: ChartPieIcon,
    hide: !isAdmin.value,
  },
  {
    name: "Projects",
    page: { name: "projects" },
    icon: BriefcaseIcon,
    hide: !isAdmin.value,
  },
  {
    name: "Archive",
    page: { name: "projects:archive" },
    icon: ArchiveIcon,
    hide: !isAdmin.value,
  },
  {
    name: "Fulfillment",
    page: { name: "fulfillment" },
    icon: TruckIcon,
    hide: isAdmin.value,
  },
  {
    name: "Global Ship Configurations",
    page: { name: "shipping:packaging" },
    icon: AdjustmentsIcon,
    hide: !isAdmin.value,
  },
];

</script>
