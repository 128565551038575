import type { RouteRecordRaw } from 'vue-router';

import Setup from './Setup.vue';
import FirstStep from './components/FirstStep.vue';
import SecondStep from './components/SecondStep.vue';
import ThirdStep from './components/ThirdStep.vue';

export const SetupRoutes: Array<RouteRecordRaw> = [
  {
    path: '/setup',
    name: 'setup',
    component: Setup,
    children: [
      {
        path: '',
        alias: 'step-one',
        name: 'setup:step-one',
        props: true,
        component: FirstStep,
      },
      {
        path: 'step-two',
        name: 'setup:step-two',
        props: true,
        component: SecondStep,
      },
      {
        path: 'step-three',
        name: 'setup:step-three',
        props: true,
        component: ThirdStep,
      },
    ],
  },
];
