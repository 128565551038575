import type { DeepReadonly, ComputedRef, Ref } from 'vue';
import type { TokensPayload, User } from '../api/auth/interfaces/auth.types';
import type { Credentials } from '../api/auth/credentials.strategy';

import { ref, watch, computed, readonly } from 'vue';
import { useApolloClient } from '@vue/apollo-composable';
import { useStorage } from '@vueuse/core';

import { createAuthStrategy } from '../api/auth/credentials.strategy';

type AppUser = { isAdmin: boolean } & User;

// Global User state
const CurrentUser = useStorage<AppUser>('user', {} as AppUser);
const access_token = useStorage<string>('access_token', null);
const refresh_token = useStorage<string>('refresh_token', null);

const _setUser = (update: User) => {
  CurrentUser.value = {
    ...update,
    isAdmin: (update?.group.name.toLocaleLowerCase().includes('admin') || update?.isSuperAdmin) ?? false,
  };
};

export const isAuthenticated = computed(() => !!access_token.value);

export const isAdmin = computed(() => CurrentUser.value.isAdmin);

watch(
  () => CurrentUser,
  (nextUser) => console.debug('Incoming User State', nextUser)
);

interface UseAuth {
  readonly user: Ref<DeepReadonly<AppUser>>;
  isAuthenticated: ComputedRef<boolean>;
  isAdmin: ComputedRef<boolean>;
  loading: Ref<boolean>;
  login: (credentials: Credentials) => Promise<unknown>;
  logout: () => Promise<void>;
}

const auth = createAuthStrategy({
  afterLogin: async (user: User, payload: TokensPayload) => {
    access_token.value = payload.access_token;
    if (payload.refresh_token) {
      refresh_token.value = payload.refresh_token;
    }
    _setUser(user);
  },
  onTokenExpired() {
    access_token.value = null;
    refresh_token.value = null;
    CurrentUser.value = null;
    window.location.href = '/login/true';
  },
});

// Use In Setup functions
export const useAuth = (): UseAuth => {
  const loading = ref(false);
  const { resolveClient } = useApolloClient();

  const login = async (credentials: Credentials) => {
    loading.value = true;
    try {
      await auth.loginWithCredentials(credentials);
    } catch (e) {
      console.error(e);
    } finally {
      loading.value = false;
    }
  };

  const logout = async () => {
    const result = await auth.logout();
    resolveClient().clearStore();
    access_token.value = null;
    refresh_token.value = null;
    CurrentUser.value = {} as AppUser;
    console.debug('User logout complete', result);
    return result;
  };

  return {
    user: readonly(CurrentUser),
    isAuthenticated,
    isAdmin,
    loading,
    login,
    logout,
  };
};
