import gql from 'graphql-tag';

export const GET_MAPPINGVIEW_DATA = gql`
  query GetViewMappingData($projectId: ID!, $ticketId: ID!) {
    ticket(id: $ticketId) {
      id
      name
      referenceId
      ignored
      mappedItems {
        id
        quantity
        product {
          id
          name
          weightOz
          imageUrl
          mustMatchExactly
        }
      }
      isMapped
    }
    products(projectId: $projectId) {
      id
      name
      imageUrl
      shortName
      scanType
      weightOz
      orderLimit
      mustMatchExactly
      externalId
      inventoryTotal
      customsSku
      customsDescription
      customsValue
      customsOriginCountry
      hasScanData
    }
  }
`;
