import gql from 'graphql-tag';

export const GET_PROJECT_METRICS = gql`
  query GetProjectMetrics($projectId: ID) {
    projectMetrics(projectId: $projectId) {
      ordersAssigned {
        agentActiveCount
        average
        count
        date
        id
      }
      ordersCompleted {
        agentActiveCount
        average
        count
        date
        id
      }
      totalOrders
    }
  }
`;
