import gql from 'graphql-tag';

export const GET_PROJECTS = gql`
  query GetProjects {
    projects {
      id
      name
      startDate
      endDate
      gateCompany {
        name
      }
      ticketCompany {
        name
      }
      # total: ordersCount
      # pending: ordersCount( where: [{ field: "status", condition: "nin", value: ["SHIPPED", "FAILED", "CANCELLED"] }])
      # rejected: ordersCount( where: [{ field: "status", condition: "equals", value: "FAILED" }])
      # shipped: ordersCount( where: [{ field: "status", condition: "in", value: ["COMPLETED","SHIPPED"] }])
    }
  }
`;
