<template>
  <div class="min-h-screen bg-white flex" style="-webkit-app-region: drag;">
    <div class="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
      <div class="mx-auto w-full max-w-sm lg:w-96">
        <div>
          <div class="flex justify-evenly items-baseline">
            <img class="h-36 w-auto" :src="logo" alt="PushPass App Logo"
              :style="isStaging ? 'filter: invert(1)' : ''" />
          </div>

          <h1 v-if="isLocal" class="mt-6 bg-red-200 text-2xl text-center font-semibold text-red-500 rounded">
            Local Configurations
          </h1>
          <h2 class="mt-14 text-xl font-extrabold text-gray-900">Sign in to your account</h2>

          <!-- TODO User Registration -->
          <!-- <p class="mt-2 text-sm text-gray-600">
            Or
            {{ ' ' }}
            <a href="#" class="font-medium text-indigo-600 hover:text-primary-600">
              register a new user
            </a>
          </p>-->
        </div>

        <div class="mt-8" style="-webkit-app-region: none;">
          <div class="mt-6">
            <LoginForm @submit="signIn" />
          </div>
        </div>
      </div>
    </div>
    <div class="hidden lg:block relative w-0 flex-1">
      <div class="absolute inset-0 h-full w-full bg-blue-700 opacity-40 z-10"></div>
      <img class="absolute inset-0 h-full w-full object-cover" style="filter: hue-rotate(60deg)" :src="loginBg" />
    </div>
  </div>
  <Toasts />
  <LoaderOverlay :loading="loading" />
</template>

<script setup lang="ts">
import type { PropType } from 'vue';
import type { Credentials } from '@api/auth/credentials.strategy';

import { onMounted, onUnmounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useAuth } from '@composables/useAuth';

import { AlertType, sendAlert } from '@services/toastService';
import LoginForm from './components/LoginForm.vue';

import loginBg from '@assets/images/concert3-bg-optimized.jpeg';
import logo from '@assets/images/push-pass-logo-blue-stacked.svg';
import LoaderOverlay from '@components/common/LoaderOverlay.vue';
import Toasts from '@components/common/Toasts.vue'

const props = defineProps({
  hasTimedOut: {
    type: String as PropType<'true' | 'false'>,
    default: 'false',
  },
});

const { push } = useRouter();
const loading = ref(false);
const { user, login } = useAuth();
const isStaging = (window as any)?.apiServerURL.includes('staging');
const isLocal = (window as any)?.apiServerURL.includes('localhost');


const alert = (type: AlertType, title: string, body: string) =>
  sendAlert({
    type,
    message: { title, body },
    timeout: 1500,
  });

onMounted(() => {
  if (props.hasTimedOut === 'true') {
    alert(AlertType.WARNING,
      'Session Timed Out',
      'Please Log in again.');
  }
});

onUnmounted(() => console.debug('Login Unmounted'));

const signIn = async (credentials: Credentials) => {
  loading.value = true;
  try {
    await login(credentials);

    if (user.value.type !== 'INTERNAL') {
      throw [new Error("Not authorized to access admin portal.")];
    }

    alert(AlertType.SUCCESS, 'Success', `Welcome ${user.value.username}!`);
    push({ path: '/app' });
  } catch (err) {
    console.error(err);
    alert(AlertType.CRITICAL, 'Unsuccessful Login', `${(err as Error[])[0].message}. If you think this is an error, please contact the administrator.`);
  } finally {
    loading.value = false;
  }
};

</script>
