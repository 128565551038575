import gql from 'graphql-tag';
import { PRODUCT_FULL_FRAGMENT } from '../fragments/PRODUCT_FULL_FRAGMENT';

export const GET_AGENT_TASK_BY_ID = gql`
  query GetTaskById($id: ID!) {
    fulfillmentTask(id: $id) {
      id
      classification
      status
      activeOrderId
      orderIds
      assignedBy {
        id
        person {
          firstName
          lastName
        }
      }
      assignee {
        id
        person {
          firstName
          lastName
        }
      }
      startedOn
      scheduledStart
      expectedShipDate
      createdAt
      updatedAt
    }
  }
  ${PRODUCT_FULL_FRAGMENT}
`;
