import { v4 } from 'uuid';

export enum AuthGroup {
  ADMIN = 'Admin',
  SUPERADMIN = 'SuperAdmin',
  AGENT = 'Agent',
  GUEST = 'Guest',
}

export class PersonModel {
  id?: string;
  firstName: string;
  lastName: string;
  title: string;
  email: string;
  imageUrl?: string | null | undefined;

  constructor(person: Person) {
    this.id = v4();
    this.firstName = person.firstName;
    this.lastName = person.lastName;
    this.title = person.title;
    this.email = person.email;
    this.imageUrl = person.imageUrl;
  }
}

export const createUser = (user: User): User => {
  return {
    id: user?.id ?? v4(),
    username: user.username,
    groups: user.groups,
    group: user.group,
    person: new PersonModel(user.person),
  };
};
