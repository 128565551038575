import gql from 'graphql-tag';

export const GET_SHIPMENT_STATS_BY_PROJECT_ID = gql`
  query ProjectShipmentTransitStats($projectId: ID!) {
    shipmentStatsByProject(projectId: $projectId) {
      id
      project {
        id
        name
      }
      statuses {
        key
        value
      }
    }
  }
`;
