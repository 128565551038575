export const getTimeZoneDate = (date: Date, options = {}): string => {
  const locale = Intl.DateTimeFormat().resolvedOptions().locale;

  const mergedOptions = {
    ...options,
    ...{
      month: 'long',
      day: 'numeric',
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
  };

  return new Date(date).toLocaleString(locale, mergedOptions);
};
