<template>
  <!-- Global notification live region, render this permanently at the end of the document -->
  <div class="fixed w-full bottom-0 sm:w-1/2 md:w-1/3 pointer-events-none z-50" :class="[
    position === 'top-left' && 'sm:top-0 sm:left-0',
    position === 'top-right' && 'sm:top-0 sm:right-0',
    position === 'bottom-left' && 'sm:bottom-0 sm:left-0',
    position === 'bottom-right' && 'sm:bottom-0 sm:right-0',
    position === 'centered-top' && 'sm:top-0 sm:w-full',
    position === 'centered-bottom' && 'sm:bottom-0 sm:w-full',
  ]">
    <div class="w-full">
      <div class="flex py-2 pr-3 justify-end">
        <button v-if="alerts.length > 5"
          class="px-2 font-medium text-right text-primary-500 underline pointer-events-auto focus:outline-none rounded cursor-pointer z-50"
          @click="onDismissAll">Dismiss All {{ alerts.length }}</button>
      </div>
      <transition-group tag="ul" enter-active-class="transform transition-all ease-out duration-300 "
        enter-from-class="opacity-0 translate-y-2 sm:translate-y-0 sm:translate-x-2"
        enter-to-class="opacity-100 translate-y-0 sm:translate-x-0"
        leave-active-class="transform transition-all ease-in duration-500"
        leave-from-class="opacity-100 translate-y-0 sm:translate-x-0"
        leave-to-class="opacity-0 translate-y-2 sm:translate-y-0 sm:translate-x-2">
        <li v-for="(alert, alertIdx) in alerts" :key="alert.id">
          <BaseToast v-if="alertIdx < 20" class="max-w-full"
            :class="[!position.startsWith('centered') && 'max-w-screen-sm']" :toast="alert" @close="onDismiss(alert)" />
        </li>
      </transition-group>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { PropType } from 'vue';
import type { Alert } from '@services/toastService';
import BaseToast from '@components/base/BaseToast.vue';
import { alerts, dismissAlert, dismissAll } from '@services/toastService';

type ToastPositions =
  | 'top-left'
  | 'top-right'
  | 'bottom-left'
  | 'bottom-right'
  | 'centered-top'
  | 'centered-bottom'

const props = defineProps({
  position: {
    type: String as PropType<ToastPositions>,
    default: 'top-right',
  },
});
const onDismiss = (alert: Alert) => {
  if (alert.id) dismissAlert(alert.id);
};
const onDismissAll = () => dismissAll();
</script>
