interface expiringDataStore {
  validUntil: Date;
  value: unknown;
}

const defaultExpireInMinutes = import.meta.env.VITE_HOMEBREW_CACHE_DEFAULT_EXPIRE_IN_MINUTES || 5;

function addMinutesToDate(date: Date, minutes: number): Date {
  return new Date(new Date(date).setMinutes(date.getMinutes() + minutes));
}
function getKey(key) {
  return `${window.cacheKey}-${key}`;
}

export const setData = (incomingKey: string, value: unknown, expire = defaultExpireInMinutes) => {
  const key = getKey(incomingKey);
  if (value !== undefined) {
    const data = {
      validUntil: addMinutesToDate(new Date(), expire),
      value: JSON.parse(JSON.stringify(value)), // get rid of Vue proxy
    } as expiringDataStore;

    window.localCache.setItem(key, data);
  }
};

export const getData = async (incomingKey: string) => {
  const key = await getKey(incomingKey);

  try {
    const data = await window.localCache.getItem(key);

    if (data === undefined || data === null) {
      // like this so it can cache false
      return undefined;
    }
    if (new Date(data.validUntil) > new Date()) {
      return data.value;
    } else {
      await window.localCache.removeItem(key);
      return undefined;
    }
  } catch (err) {
    console.log(err);
  }
};
