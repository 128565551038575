<template>
  <Section>
    <Form title="Step 3">
      <GridGroup>
        <GridField title="Server URI" placeholder="Enter A Value" @change="handleChange" />
        <GridField title="Application for" placeholder="Backend Developer" />
      </GridGroup>
      <template #actions>
        <div class="flex my-4 justify-end">
          <BaseButton size="md" @click="submit">
            Go To Login
          </BaseButton>
        </div>
      </template>
    </Form>
  </Section>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import { AlertType, sendAlert } from '@services/toastService';
import { useStateProvider } from '../provider/SetupStateProvider.vue';
import BaseButton from '@components/base/BaseButton.vue';

export default defineComponent({
  setup() {
    const { current, goToNext, updateStatus } = useStateProvider();
    const { replace } = useRouter();

    const handleChange = (event: Event) => {
      const target = event.target as HTMLInputElement;
    };

    const isValid = () => true;

    const submit = () => {
      if (isValid()) {
        updateStatus(current, 'complete');
        !goToNext() && replace({ name: 'login' });
      } else {
        sendAlert({
          id: 4,
          type: AlertType.WARNING,
          message: {
            title: 'Invalid Configs',
            body: 'This configuration is incorrect. Please try again',
          },
          timeout: 3000,
        });
      }
    };

    return {
      submit,
      handleChange,
    };
  },
});
</script>
