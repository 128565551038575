import type { Strategy } from './interfaces/auth-strategy.interface';
import type { TokensPayload, User } from './interfaces/auth.types';
import type { JwtPayload } from 'jwt-decode';

import JwtDecode from 'jwt-decode';

import { resolveUser, loginWithCredentials, logoutUser } from './auth';

export type Credentials = { username: string; password: string };

const TOKEN_CHECK_EVERY_MINUTE = 60 * 1000;

/**
 * Checks if the token is valid.
 * Returns an array with two elements:
 * a boolean indicating if the token is valid, and
 * a number representing the remaining seconds until the token expires.
 *
 * @return {[boolean, number]} [boolean, number]
 */
const isTokenValid = (): [boolean, number] => {
  const token = localStorage.getItem('access_token');
  if (!token) return [false, 0];

  // No Expiration Date Set
  const { exp } = JwtDecode(token) as JwtPayload;
  if (!exp) return [true, 0];

  const currentTime = Math.floor(new Date().getTime() / 1000); // Current Time since epoch as seconds
  const remainingTime = exp - (currentTime - TOKEN_CHECK_EVERY_MINUTE / 1000);
  console.debug('[TOKEN] Token Expires:', exp, 'Current Time:', currentTime, 'Remaining Time:', remainingTime);
  return [!(exp < remainingTime), remainingTime];
};

let tokenChecker: NodeJS.Timeout;
const startTokenChecker = (checkerFn: () => void): void => {
  clearInterval(tokenChecker);
  tokenChecker = setInterval(checkerFn, TOKEN_CHECK_EVERY_MINUTE);
};
const checkToken = (handleInvalid: () => void) => {
  console.groupCollapsed('Auto Token Checker');

  const [valid, remainingTime] = isTokenValid();
  if (!valid) {
    console.debug('Token Invalid');
    clearInterval(tokenChecker);
    handleInvalid();
  }
  console.debug(`Token Valid for ${remainingTime} seconds`);
  console.groupEnd();
};

interface CredStrategyParams {
  afterLogin: (user: User, payload: TokensPayload) => Promise<void>;
  afterLogout?: () => Promise<void> | void;
  onTokenExpired: () => Promise<void> | void;
}

export type CredentialsStrategyFactory = (params: CredStrategyParams) => Strategy & {
  loginWithCredentials: (credentials: Credentials) => Promise<[User, TokensPayload]>;
  resolveCurrentUser: (token: string) => Promise<User>;
};

export const createAuthStrategy: CredentialsStrategyFactory = ({
  afterLogin: handleLogin,
  afterLogout: handleLogout,
  onTokenExpired,
}) => {
  return {
    async loginWithCredentials({ username = '', password = '' }: Credentials) {
      // Native Server Login Flow
      console.debug('Attempting to login', username, password);
      const { errors, payload } = await loginWithCredentials(username, password);

      if (errors?.length) {
        throw errors;
      }
      if (!payload) {
        throw new Error('Error Logging in. Unable to receive auth tokens.');
      }

      startTokenChecker(() => checkToken(onTokenExpired));
      const user = await resolveUser(payload.access_token);
      await handleLogin(user, payload);
      return [user, payload];
    },

    async resolveCurrentUser(token: string) {
      return resolveUser(token);
    },

    async logout() {
      clearInterval(tokenChecker);
      await logoutUser();
      handleLogout && handleLogout();
    },
  };
};
