import gql from 'graphql-tag';

export const GET_ORDER = gql`
  query GetOrder($id: ID!) {
    order(id: $id) {
      id
      status
      createdAt
      clientOrderId
      project {
        id
        name
      }
      deliveryType
      shipSetting {
        id
        name
        shipMethod {
          id
          name
        }
      }
      shipMethod {
        id
        name
      }
      address {
        contactName
        email
        phone
        errorReports {
          field
          message
        }
        ...OrderAddressDetails
      }
      assignee {
        person {
          email
          firstName
          imageUrl
          lastName
          phone
          title
        }
      }
      shipment {
        id
        toAddress {
          contactName
          ...AddressDetails
        }
        fromAddress {
          phone
          email
          ...AddressDetails
        }
        status
        shippedOn
        carrier {
          id
          name
        }
        service
        trackingCode
        trackerUrl
        postageLabel {
          labelUrl
        }
      }
      tickets {
        ticket {
          id
          name
        }
        details {
          reference
        }
        quantity
      }
      lineItems {
        id
        product {
          id
          name
          weightOz
          imageUrl
          scanType
          mustMatchExactly
        }
        reference
        scanValue
        status
      }
      classification
      fulfillmentTask {
        id
        assignedOn
        assignee {
          id
          person {
            firstName
            lastName
          }
        }
      }
      history {
        date
        details
        event
        user
      }
    }
  }
  fragment OrderAddressDetails on OrderAddress {
    id
    verified
    street1
    street2
    street3
    city
    state
    postalCode
    country
    type
  }
  fragment AddressDetails on ShipmentAddress {
    id
    verified
    street1
    street2
    street3
    city
    state
    postalCode
    country
    type
  }
`;
