<template>
  <div class="mx-auto pt-6 sm:mx-0 text-gray-800">
    <component :is="`h${level}`" class="leading-tight" :class="[textSize, textWeight]">
      {{ title }}
    </component>
    <h3 class="text-base font-light text-gray-600">
      {{ subtitle }}
    </h3>
    <slot />
  </div>
</template>

<script setup lang="ts">
import type { PropType } from 'vue';
import { computed } from 'vue';

const TextWeight = {
  thin: 'font-thin',
  extraLight: 'font-extralight',
  light: 'font-light',
  normal: 'font-normal',
  medium: 'font-medium',
  semiBold: 'font-semibold',
  bold: 'font-bold',
  extraBold: 'font-extrabold',
  black: 'font-black',
};

const props = defineProps({
  title: {
    type: String as PropType<string>,
    default: 'Page Title',
  },
  subtitle: {
    type: String as PropType<string>,
    default: '',
  },
  level: {
    type: Number as PropType<1 | 2 | 3 | 4 | 5 | 6>,
    default: 1,
  },
  weight: {
    type: String as PropType<keyof typeof TextWeight>,
    default: 'bold',
  },
});

const textSize = computed(() => {
  return (
    (props.level === 6 && 'text-sm')
    || (props.level === 5 && 'text-base')
    || (props.level === 4 && 'text-lg')
    || (props.level === 3 && 'text-xl')
    || (props.level === 2 && 'text-2xl')
    || 'text-3xl'
  );
});

const textWeight = computed(() => {
  return TextWeight[props.weight] as string;
});

</script>
