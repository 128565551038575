import type { RouteRecordRaw } from 'vue-router';

export const ShippingRoutes: Array<RouteRecordRaw> = [
  {
    path: 'shipping',
    component: () => import('./ShippingOverview.vue'),
    children: [
      {
        path: '',
        name: 'shipping',
        component: () => import('./components/ShipmentPackagingTable.vue'),
      },
      {
        path: 'packaging',
        name: 'shipping:packaging',
        component: () => import('./components/ShipmentPackagingTable.vue'),
      },
      {
        path: 'carriers',
        name: 'shipping:carriers',
        component: () => import('./components/CarriersTable.vue'),
      },
      {
        path: 'methods',
        name: 'shipping:methods',
        component: () => import('./components/SettingsForm.vue'),
      },
    ],
  },
];
