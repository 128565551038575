import gql from 'graphql-tag';

export const GET_AGENT_TASKS_BY_QUEUE = gql`
  query FulfillmentTasks($where: [WhereFilter]) {
    fulfillmentTasks(where: $where) {
      id
      classification
      assignee {
        id
        person {
          firstName
          lastName
          title
        }
      }
    }
  }
`;
