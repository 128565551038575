<template>
  <MultiStepForm :steps="steps" />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useStateProvider } from '@views/setup/provider/SetupStateProvider.vue';

export default defineComponent({
  emits: ['update'],
  setup() {
    const { steps, current } = useStateProvider();

    return {
      steps,
      current,
    };
  },
});
</script>
