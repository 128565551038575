<template>
  <BarChart ref="graphRef" :chart-data="metricsCompletedBarChartGraphData" :options="options" />
</template>

<script setup lang="ts">
import type { PropType } from 'vue';
import { ref } from 'vue';
import { getTimeZoneDate } from "@utils/timeZoneUtils";
import type { ExtractComponentData } from 'vue-chart-3';
import { BarChart } from 'vue-chart-3'; // FIXME dependency should be hidden in Base or Common component
import type { ChartData, ChartOptions } from "chart.js";

import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);

interface configType {
  labelName: string,
  barThickness: Number,
  borderRadius: Number,
  backgroundColor: string
}

const props = defineProps({
  data: {
    type: Object as PropType<[]>,
    required: true,
  },
  options: {
    type: Object as PropType<configType>,
    required: true,
  },
  graphTitle: {
    type: String as PropType<string>,
    required: true,
  },
});
const animation = props.data.length < 10;

const graphRef = ref<ExtractComponentData<typeof BarChart>>();

const options = ref<ChartOptions<'bar'>>({
  responsive: true,
  indexAxis: 'x',
  layout: {
    autoPadding: true,
  },
  plugins: {
    legend: {
      position: 'top',
      display: false,
    },
    title: {
      display: true,
      text: props.graphTitle,
      padding: {
        bottom: 20
      }
    },
  },
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: false
    }
  },
  animation: animation
});

const metricsCompletedBarChartGraphData = ref<ChartData<"bar">>({
  labels: [],
  datasets: []
});

const getDataSet = (collection: any[], config: configType) => {

  const sortDates = (collection: any[]) => {
    const allLabels = collection.reduce((labels: Set<Date>, record: any) => {
      // Date must be timezone adjusted
      // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/Date#sect2
      if (record.count > 0) return labels.add(new Date(record.date.split('T')[0] + 'T01:00'));

    }, new Set()) as Set<Date>;

    const sortedDates = [...allLabels].sort((date1, date2) => date1.getTime() - date2.getTime());

    let dates: string[] = []

    sortedDates.forEach(element => dates.push(getTimeZoneDate(element, { year: 'numeric' })));

    return dates;
  };

  const dataSet: { label: string; data: number[]; barThickness: any; borderRadius: any; backgroundColor: any; }[] = [];
  const dates = sortDates(collection);

  collection.forEach((record: { date: Date; count: number; }) => {
    const adjustedDate = new Date(record.date.split('T')[0] + 'T01:00').toISOString()
    const date = getTimeZoneDate(adjustedDate, { year: 'numeric' });

    let data = [];

    dates.forEach((d) => {
      data.push(0)
    });

    data[dates.indexOf(date)] = record.count;

    dataSet.push({
      label: `${date} ${config.labelName}`,
      data: data,
      barThickness: config.barThickness,
      borderRadius: config.borderRadius,
      backgroundColor: config.backgroundColor,
    })
  });

  return {
    labels: dates,
    datasets: dataSet,
  }
}

metricsCompletedBarChartGraphData.value = getDataSet(props.data, props.options);


</script>
