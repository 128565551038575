<template>
  <form class="space-y-6" @submit.prevent="validate">
    <div>
      <label for="username" class="block text-sm font-medium text-gray-700">
        Username
      </label>
      <div class="mt-1">
        <input id="username" v-model="username" name="username" type="text" autocomplete="username" required="true" class="
            appearance-none
            block
            w-full
            px-3
            py-2
            border border-gray-300
            rounded-md
            shadow-sm
            placeholder-gray-400
            focus:outline-none
            focus:ring-primary-600
            focus:border-primary-600
            sm:text-sm
          ">
      </div>
    </div>

    <div class="space-y-1">
      <label for="password" class="block text-sm font-medium text-gray-700">
        Password
      </label>
      <div class="mt-1">
        <input id="password" v-model="password" name="password" type="password" required="true"
          autocomplete="current-password" aria-autocomplete="both" class="
            appearance-none
            block
            w-full
            px-3
            py-2
            border border-gray-300
            rounded-md
            shadow-sm
            placeholder-gray-400
            focus:outline-none
            focus:ring-primary-600
            focus:border-primary-600
            sm:text-sm
          ">
      </div>
    </div>
    <!-- TODO store username for quicker login -->
    <div class="flex items-center justify-between">
      <div class="flex items-center">
        <input id="remember_me" name="remember_me" type="checkbox" :checked="rememberLogin"
          class="h-4 w-4 text-indigo-600 focus:ring-primary-600 border-gray-300 rounded" @click="toggle">
        <label for="remember_me" class="ml-2 block text-sm text-gray-900">
          Remember me
        </label>
      </div>
    </div>

    <div>
      <button type="submit"
        class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-600">
        Sign in
      </button>
    </div>
  </form>
</template>

<script setup lang="ts">
import type { Credentials } from '@api/auth/credentials.strategy';
import { onMounted, ref, watch } from 'vue';
import { useToggleState } from '@composables/useToggleState';

const emit = defineEmits(['submit']);

const errors = [];
const username = ref('');
const password = ref('');
const { state: rememberLogin, toggle } = useToggleState(await window.localConfigs.getItem('login.remember') || false);

watch(rememberLogin, (newValue) => {
  window.localConfigs.setItem('login.remember', newValue)
  newValue === false && (username.value = '')
});

onMounted(async () => {
  if (rememberLogin.value === true) {
    username.value = await window.localConfigs.getItem('login.username');
  }
})

const submit = async () => {
  const payload = { username: username.value, password: password.value } as Credentials;

  if (await window.localConfigs.getItem('login.remember') === true) {
    window.localConfigs.setItem('login.username', username.value);
  } else {
    window.localConfigs.removeItem('login.username');
    window.localConfigs.removeItem('login.password');
  }

  emit('submit', payload);
};

const validate = () => {
  if (!username.value || !password.value) {
    errors.push('Username and Password Required');
  }
  submit();
};

</script>
